export function formatAndMaskCPF(cpf) {
    const value = cpf.replace(/\D/g, "");

    if (value.length !== 11) {
        return value;
    }
    
    const formattedCPF = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    const maskedCPF = formattedCPF.replace(/(\d{3})\.(\d{3})\.(\d{2})(\d)-(\d{2})/, "$1.xxx.xx$4-$5");
    
    return maskedCPF;
  }