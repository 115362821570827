<template>
  <div class="cardProducts">
    <CardProduct v-for="(item, index) in itemsPaginate" :item="item" :index="index" :key="index" />
  </div>
</template>

<script>
import TableQuantityCounter from "./TableQuantityCounter";
import TableButtonsAction from "./TableButtonsAction";
import TableSegregation from "./TableSegregation";
import TablePrice from "./TablePrice";
import getImage from "../../utils/getImage.js"
import {
  CMenu,
  CMenuButton,
  CMenuList,
  CMenuItem,
  CMenuGroup,
  CMenuDivider,
  CMenuOptionGroup,
  CMenuItemOption,
} from "@chakra-ui/vue";
import CardProduct from "./CardProduct.vue";

export default {
  name: "CardProducts",
  components: {
    TableQuantityCounter,
    TableButtonsAction,
    TableSegregation,
    TablePrice,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuItem,
    CMenuGroup,
    CMenuDivider,
    CMenuOptionGroup,
    CMenuItemOption,
    CardProduct,
  },
  methods: {
    getImage,
    showDetails(id) {

      if (this.$route.name === "HomeB2b") {
        this.$router.push({ name: "MoreB2b", params: { item: id } })
      } else {
        this.$router.push({ name: "MoreAfv", params: { item: id } })
      }
    },
    handleApproval(itemId) {
      this.$store.state.data.items[itemId].aprovado = !this.$store.state.data.items[itemId].aprovado;
    },
    refresh(item) {
      if (this.$store.state.data.items[item].segregacoes.length) {
        const quantidadeOriginal = this.$store.state.data.items[item].quantidadeOriginal
        const precoOriginal = this.$store.state.data.items[item].precoOriginal

        this.$store.state.data.items[item].quantidade = quantidadeOriginal
        this.$store.state.data.items[item].preco = precoOriginal
        this.$store.state.data.items[item].aprovado = false

        this.$store.commit("setQuantityItem")

      }
      else {
        this.$store.state.data.items[item].quantidade = this.$store.state.data.items[item].quantidadeOriginal
        this.$store.state.data.items[item].preco = this.$store.state.data.items[item].precoOriginal
        this.$store.state.data.items[item].aprovado = false
      }
      this.$store.commit("setQuantityItem")
      this.$store.commit('updateItem',
        this.$store.state.data.items[item]
      )

      if (
        Number(this.$store.state.data.items[item].quantidade).toFixed(3) !== Number(this.$store.state.data.items[item].quantidadeOriginal).toFixed(3) ||
        this.$store.state.data.items[item].preco !== this.$store.state.data.items[item].precoOriginal
      ) {
        if (!this.$store.state.data.items[item].hasOwnProperty('justificativa')) {
          this.$store.commit("setItemJustificativa", { add: true, index: item });
        }
      } else if (!this.$store.state.data.items[item].bloqueado) {
        this.$store.commit("setItemJustificativa", { add: false, index: item });
      }
    },
    aprove(itemIndex) {
      this.$store.state.data.items[itemIndex].aprovado = true;
    },
    reject(itemIndex) {
      this.$store.state.data.items[itemIndex].aprovado = false;
    },
  },
  computed: {
    itemsPaginate() {
      return this.$store.state.itemsPaginate
    },

  },

};
</script>

<style scoped>
.cardProducts {
  display: flex;
  flex-direction: column;
}

.moreInformationButton {
  color: #169840 !important;
}

.price_info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 600px) {
  .price_info {
    flex-wrap: wrap;
  }

  .price_field:first-child {
    width: 100%;
  }
}

.price_field {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imposto_field {
  height: 40px;
  border: 1px solid #eee;
  padding: 10px 30px;
  color: #aaa;
  cursor: default;
}

.motivo_wrapper {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

@media (max-width: 600px) {
  .motivo_wrapper {
    flex-wrap: wrap;
  }

  .motivo_field textarea {
    width: 100%;
  }
}

.motivo_field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card {
  background: #fff;
  border: solid 1px #999;
  border-bottom: 0;
  display: block;
  padding: 20px;
}

.card:last-child {
  border-bottom: solid 1px #999;
}

.imageProductContainer {
  margin-right: 15px;
}

.imageProductContainer>img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.productDataContainer {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.productDescription {
  font-size: 15px;
  font-weight: 500;
  word-break: break-all;
  margin-bottom: 5px;
}

.productCod {
  color: #707070;
  margin-bottom: 8px;
}

.productValue {}

.productValue.valueComImposto {}

.productValue.valueSemImposto {
  color: #707070;
}

.quantityAndResetButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.moreInformationButton {
  color: #169840;
  word-break: initial;
}
</style>