<template>
  <div class="card">
    <div style="display: flex; margin-bottom: 5px; gap: 20px;align-items:flex-start;">
      <Checkbox :isChecked="selecteds.includes(item.codigo)" @on-checked-change="handleItemActive" />

      <div class="imageProductContainer">
        <img :src="getImage(item.imagem)" alt="" />
      </div>
      <div class="productDataContainer">
        <div style="display:flex; align-items: center; gap: 10px;">
          <!-- <div style="display: flex; align-items: center; gap: 10px; flex: 1;">
            <span v-if="item.bloqueado && item.aprovado === false"
              style="font-size:11px;color:white;background:red;padding:2px;border-radius:4px;">REJEITADO</span>
            <span v-if="item.bloqueado && item.aprovado === true"
              style="font-size:11px;color:white;background:#169840;padding:2px;border-radius:4px;">APROVADO</span>
            <button v-if="item.bloqueado" @click="() => handleApproval(item.positionArray)"
              :class="['approval_btn', { aprovado: item.aprovado, rejeitado: !item.aprovado }]">
              {{ item.aprovado ? 'REJEITAR' : 'APROVAR' }}
            </button>
            <button @click="() => refresh(item.codigo)" :class="['approval_btn', 'rejeitado']">
              RESTAURAR
            </button>
          </div> -->

          <a href="" @click.prevent="() => modalOpen = !modalOpen" style="" v-if="item.cSLL !== undefined">Ver
            margem</a>

        </div>

        <!-- <div v-if="item.bloqueado" style="display:flex;gap:10px;margin: 5px 0;padding: 8px 4px;background-color:#eee;">
          <div>
            <p><b>Motivo</b></p>
            <p>{{ item.motivo === "descontoMax" ? 'Desconto máx. excedido' : 'Margem mín. não atingida' }}</p>
          </div>

          <div>
            <p><b>Limite</b></p>
            <p
              style="font-size:12px;padding: 2px;border:1px solid green;border-radius:4px;color:green;text-align:center;width:55px;background:#fff;">
              {{ String(item.limite.toFixed(2)).replace('.', ',') }}%</p>
          </div>

          <div>
            <p><b>Proposto</b></p>
            <p
              style="font-size:12px;padding: 2px;border:1px solid red;border-radius:4px;color:red;text-align:center;width:55px;background:#fff;">
              {{ String(item.proposto.toFixed(2)).replace('.', ',') }}%</p>
          </div>

          <div v-if="item.cSLL !== undefined">
            <p><b>MC %</b></p>
            <p
              style="font-size:12px;padding: 2px;border:1px solid #000;border-radius:4px;color:#000;text-align:center;width:55px;background:#fff;">
              {{ new Intl.NumberFormat("pt-br", {
                style: "decimal",
                currency: "BRL",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                roundingMode: "floor",
              }).format(percMargem(item)) }}%</p>
          </div>
        </div> -->

        <span class="descriptionLegend" style="color: #707070;font-size:12px;">{{ item.codigo }}
        </span>
        <span class="productDescription">{{ item.descricao }}</span>
        <span class="descriptionLegend" style="margin-bottom: 10px; color: #707070;font-size:12px;">
          Cód. barras: {{ item.ean13 }}
        </span>
      </div>
    </div>

    <div class="price_info">
      <div class="price_field">
        <div style="display:flex;gap:10px;align-items:flex-end;">
          <div v-show="!item.segregacoes.length">
            <span style="text-align:center;display:block;">Quantidade</span>
            <TableQuantityCounter :item="item" />
          </div>

          <div v-show="item.segregacoes.length">
            <span style="text-align:center;display:block;">Quantidade</span>
            <TableSegregation v-show="item.segregacoes.length" :item="item" :id="index" />
          </div>


          <button @click="() => refresh(item.codigo)"><md-icon
              style="margin:0;margin-bottom:10px;">replay</md-icon></button>
        </div>
      </div>


      <div style="display:flex;gap:20px;">
        <div class="price_field">
          <span>Valor sem imposto</span>
          <TablePrice :item="item" />
        </div>

        <div class="price_field">
          <span>Valor com imposto</span>
          <div class="imposto_field">
            {{
              new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: casasDecimais,
                maximumFractionDigits: casasDecimais,
              }).format(item.precoComImpostos)
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="motivo_wrapper" v-if="hasJustificativa">
      <div class="block-reason" v-if="item.bloqueado">
        <div>
          <span>Motivo do bloqueio</span>
          <span style="font-size:12px;">
            {{ item.motivo === "descontoMax" ? 'Desconto máx. excedido' : 'Margem mín. não atingida' }}
          </span>
        </div>

        <div>
          <span>Limite</span>
          <span style="font-size:12px;text-align:center;">
            {{ String(item.limite.toFixed(2)).replace('.', ',') }}%</span>
        </div>

        <div>
          <span>Proposto</span>
          <span style="font-size:12px;color:#D9290B;text-align:center;">
            {{ String(item.proposto.toFixed(2)).replace('.', ',') }}%</span>
        </div>
      </div>

      <div class="justify-block" :class="{ empty: !item.justificativa }">
        <div>
          <span>Justificativa</span>
          <span>{{ item.justificativa || "Aprove ou reprove o produto bloqueado" }}</span>
        </div>
        <div style="display:flex;gap:10px;">
          <button @click="() => handleApproval('approve')"><md-icon style="color:#169840;">{{ item.aprovado ?
            "thumb_up_alt" : "thumb_up_off_alt"
              }}</md-icon></button>
          <button @click="() => handleApproval('reject')"><md-icon style="color:#D9290B;">{{ !item.aprovado ?
            "thumb_down_alt" : "thumb_down_off_alt"
              }}</md-icon></button>
        </div>
      </div>
    </div>

    <ModalProductValues v-if="modalOpen" :item="item" @close="modalOpen = false" />
    <ModalJustifyOne v-if="isModalJustifyOpen" :productCode="item.codigo" :action="justifyAction"
      @close="isModalJustifyOpen = false" />
  </div>
</template>

<script>
import TableQuantityCounter from "./TableQuantityCounter";
import TableButtonsAction from "./TableButtonsAction";
import TableSegregation from "./TableSegregation";
import TablePrice from "./TablePrice";
import ModalProductValues from "../Modals/ModalProductValues.vue";
import getImage from "../../utils/getImage.js";
import { mapGetters } from "vuex";
import {
  CMenu,
  CMenuButton,
  CMenuList,
  CMenuItem,
  CMenuGroup,
  CMenuDivider,
  CMenuOptionGroup,
  CMenuItemOption,
} from "@chakra-ui/vue";
import Checkbox from "../Checkbox/Checkbox.vue";
import ModalJustifyOne from "../Modals/ModalJustifyOne.vue";

export default {
  name: "CardProduct",
  components: {
    TableQuantityCounter,
    TableButtonsAction,
    TableSegregation,
    TablePrice,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuItem,
    CMenuGroup,
    CMenuDivider,
    CMenuOptionGroup,
    CMenuItemOption,
    ModalProductValues,
    ModalJustifyOne,
    Checkbox,
  },
  data() {
    return {
      modalOpen: false,
      isModalJustifyOpen: false,
      justifyAction: "approve",
    };
  },
  props: ['item', 'index'],
  methods: {
    getImage,
    showDetails(id) {

      if (this.$route.name === "HomeB2b") {
        this.$router.push({ name: "MoreB2b", params: { item: id } })
      } else {
        this.$router.push({ name: "MoreAfv", params: { item: id } })
      }
    },
    handleApproval(action) {
      this.justifyAction = action;
      this.isModalJustifyOpen = true;
    },
    refresh(itemCodigo) {
      this.$store.commit("resetItem", itemCodigo);
    },
    aprove(itemIndex) {
      this.$store.state.data.items[itemIndex].aprovado = true;
    },
    reject(itemIndex) {
      this.$store.state.data.items[itemIndex].aprovado = false;
    },
    handleItemActive(checked) {
      this.$store.commit("setSelectedItem", {
        codigo: this.item.codigo,
        checked
      });
    },
  },
  computed: {
    ...mapGetters(["percMargem", "casasDecimais"]),
    itemsPaginate() {
      return this.$store.state.itemsPaginate
    },
    hasJustificativa() {
      return this.$store.getters['hasJustificativa'](this.item['codigo'])
    },
    selecteds() {
      return this.$store.state.selectedItems
    },
  }
}
</script>

<style scoped>
.cardProducts {
  display: flex;
  flex-direction: column;
}

.approval_btn {
  border: 1px solid;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.approval_btn i {
  font-size: 19px !important;
}

.approval_btn.rejeitado {
  color: #169840;
  border-color: #169840;
}

.approval_btn.rejeitado i {
  color: #169840;
}

.approval_btn.aprovado {
  color: red;
  border-color: red;
}

.approval_btn.aprovado i {
  color: red;
}

.moreInformationButton {
  color: #169840 !important;
}

.price_info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 600px) {
  .price_info {
    flex-wrap: wrap;
  }

  .price_field:first-child {
    width: 100%;
  }
}

.price_field {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price_field span {
  font-size: 12px;
}

.imposto_field {
  height: 40px;
  border: 1px solid #eee;
  padding: 10px 30px;
  color: #aaa;
  cursor: default;
  background-color: #F7F7F7;
}

.motivo_wrapper {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

@media (max-width: 600px) {
  .motivo_wrapper {
    flex-wrap: wrap;
  }

  .motivo_field textarea {
    width: 100%;
  }
}

.motivo_field {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.block-reason {
  display: flex;
  gap: 20px;
  flex: 1;
  border: 1px solid #FFD174;
  background-color: #FFF9E9;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.block-reason div {
  display: flex;
  flex-direction: column;
}

.block-reason div span:first-child {
  font-weight: bold;
  margin-bottom: 10px;
}

.justify-block {
  display: flex;
  gap: 20px;
  flex: 1;
  border: 1px solid #B3B3B3;
  background-color: #F7F7F7;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.justify-block.empty {
  color: #707070;
}

.justify-block.empty div span:not(:first-child) {
  font-style: italic;
}

.justify-block div span {
  display: block;
}

.justify-block div span:first-child {
  font-weight: bold;
  margin-bottom: 10px;
}

.justify-block div span:not(:first-child) {
  font-size: 12px;
}

.card {
  background: #fff;
  border: solid 1px #e4e4e4;
  display: block;
  padding: 10px 10px;
}

.imageProductContainer {}

.imageProductContainer>img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.productDataContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.productDescription {
  font-size: 15px;
  font-weight: 500;
  word-break: break-all;
}

.productCod {
  color: #707070;
  margin-bottom: 8px;
}

.productValue {}

.productValue.valueComImposto {}

.productValue.valueSemImposto {
  color: #707070;
}

.quantityAndResetButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.moreInformationButton {
  color: #169840;
  word-break: initial;
}
</style>