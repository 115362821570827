<template>
    <div class="modal__header">
        <span>{{ title }}</span>
    </div>
</template>

<script>
export default {
    name: "ModalHeader",
    props: {
        title: {
            type: String,
            default: "",
        },
    },
}
</script>

<style scoped>
.modal__header {
    padding: 10px;
    background: #FFAA00;
    color: white;
    text-transform: uppercase;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>