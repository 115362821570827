<template>
  <div class="cardEnterprise">
    <div class="card">
      <div class="cardTitle">
        <span>Empresa Representada</span>

        <md-button
          class="md-icon-button arrowUp"
          @click="showMinimal_a = !showMinimal_a"
        >
          <md-icon v-show="showMinimal_a">keyboard_arrow_up</md-icon>
          <md-icon v-show="!showMinimal_a">keyboard_arrow_down</md-icon>
        </md-button>
      </div>
      <div class="enterpriseDetails" v-show="showMinimal_a">
        <h3 class="enterpriseDescription_line">
          {{ this.$store.state.data.representanteNome }}
        </h3>
      </div>
    </div>

    <div class="card">
      <div class="cardTitle">
        <span>Orçamento</span>

        <md-button
          class="md-icon-button arrowUp"
          @click="showMinimal_b = !showMinimal_b"
        >
          <md-icon v-show="showMinimal_b">keyboard_arrow_up</md-icon>
          <md-icon v-show="!showMinimal_b">keyboard_arrow_down</md-icon>
        </md-button>
      </div>
      <div class="enterpriseDetails" v-show="showMinimal_b">
        <div class="enterpriseDescription">
          <span class="enterpriseDescription_line"
            >Cliente:
            {{
              stringEmptyToHypen(this.$store.state.data.clienteRazaoSocial)
            }}</span
          >
          <span class="enterpriseDescription_line"
            >Email:
            {{ stringEmptyToHypen(this.$store.state.data.clienteEmail) }}
          </span>
          <span class="enterpriseDescription_line"
            >Tipo de ordem:
            {{ stringEmptyToHypen(this.$store.state.data.tipoOrdem) }}
          </span>
          <span class="enterpriseDescription_line"
            >Tipo de frete:
            {{ stringEmptyToHypen(this.$store.state.data.tipoFrete) }}
          </span>
          <span class="enterpriseDescription_line"
            >Forma de pagamento:
            {{ stringEmptyToHypen(this.$store.state.data.formaPagamento) }}
          </span>
          <span class="enterpriseDescription_line"
            >Condição de pagamento:
            {{ stringEmptyToHypen(this.$store.state.data.condicaoPagamento) }}
          </span>

          <!-- <span class="enterpriseDescription_line red"
            >Este orçamento expira no dia:
            {{ this.$store.state.data.dataExpira }}
          </span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stringEmptyToHypen from "../modules/stringEmptyToHyphen";
export default {
  name: "CardsEnterprise",
  data() {
    return {
      showMinimal_a: true,
      showMinimal_b: true,
      stringEmptyToHypen,
    };
  },
}
</script>

<style scoped>
.cardEnterprise {
  display: none;
}

@media (max-width: 768px) {
  .cardEnterprise {
    display: flex;
    flex-direction: column;
    margin-top: 65px;
    background: transparent;
  }
  .red {
    color: #da0000fa !important;
  }
  .card {
    margin: 5px 0;
    padding: 5px 10px;

    background: #fff;
  }
  .cardTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-family: "Roboto";
    text-transform: uppercase;
    color: #242424de;
  }
  .enterpriseDetails {
    padding-bottom: 15px;
  }
  .enterpriseDescription {
    display: flex;
    flex-direction: column;
  }
  .enterpriseDetails > img {
    width: 150px;
    height: 80px;
  }
  .enterpriseDetails > .enterpriseName {
  }
  .enterpriseDescription_line {
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    margin: 0;
    color: #000000;
    line-height: initial;
  }
}
</style>