<template>
    <label class="checkbox-wrapper">
        <input type="checkbox" :checked="isChecked" @change="toggle" />
        <span class="checkbox" :class="stateClass"></span>
    </label>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        isChecked: {
            type: Boolean,
            default: false
        },
        isIndetermined: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        stateClass() {
            return {
                checked: this.isChecked,
                indetermined: this.isIndetermined
            };
        }
    },
    methods: {
        toggle() {
            this.$emit("on-checked-change", !this.isChecked);
        }

    }
}
</script>

<style scoped>
.checkbox-wrapper {
    display: flex;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    width: 25px;
}

.checkbox-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox {
    position: relative;
    height: 25px;
    width: 25px;
    border-radius: 2px;
    transition: background-color 0.3s ease;
    border: 1px solid #999;
}

.checkbox-wrapper input~.checkbox.checked {
    background-color: #999;
}

.checkbox-wrapper input~.checkbox.indetermined {
    background-color: #999;
}

.checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-wrapper input~.checkbox.checked:after {
    display: block;
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox-wrapper input~.checkbox.indetermined:after {
    display: block;
    width: 10px;
    height: 2px;
    background-color: white;
    top: 12px;
    left: 7px;
}
</style>