<template>
    <ModalComponent @click="close">
        <ModalHeader :title="title" />
        <ModalContent>
            <p v-if="productName" style="font-size:12px;margin-bottom:20px;">{{ productName }}</p>

            <div style="display:flex;gap:10px;">
                <ApprovalButton action="approve" :active="localAction === 'approve'" @click="localAction = 'approve'" />
                <ApprovalButton action="reject" :active="localAction === 'reject'" @click="localAction = 'reject'" />
            </div>

            <div class="justify-content">
                <label for="justify">Justificativa</label>
                <div class="textarea-wrapper" :class="{ invalid: isInvalid }">
                    <textarea v-model="justify" id="justify" maxlength="200" @focus="isInvalid = false"></textarea>
                    <span>{{ justify.length }}/200</span>
                </div>
                <span v-if="isInvalid" style="font-size: 12px;color:red;position:absolute;bottom:-20px;left:0;">Campo
                    obrigatório</span>
            </div>
        </ModalContent>
        <ModalFooter>
            <button @click="close" class="btn-footer secondary">Cancelar</button>
            <button @click="handleJustify" class="btn-footer primary">Justificar</button>
        </ModalFooter>
    </ModalComponent>
</template>

<script>
import ModalComponent from "./Modal/ModalComponent";
import ModalHeader from "./Modal/ModalHeader.vue";
import ModalContent from "./Modal/ModalContent.vue";
import ModalFooter from "./Modal/ModalFooter.vue";
import ApprovalButton from "../ApprovalButton/ApprovalButton";

export default {
    name: "ModalJustify",
    components: {
        ModalComponent,
        ModalHeader,
        ModalContent,
        ModalFooter,
        ApprovalButton,
    },
    props: {
        action: {
            type: String,
            default: "approve", // 'approve' ou 'reject'
        }
    },
    data() {
        return {
            justify: "",
            isInvalid: false,
            localAction: this.action,
        }
    },
    computed: {
        title() {
            const itemsSelectedCount = this.$store.state.selectedItems.length;

            if (itemsSelectedCount > 1) {
                return `Justificativa de (${itemsSelectedCount}) produtos`;
            }

            return "Justificativa do produto"
        },
        productName() {
            if (this.$store.state.selectedItems.length === 1) {
                const item = this.$store.state.items.find(item => item.codigo === this.$store.state.selectedItems[0]);

                if (item) {
                    return `${item.codigo} - ${item.descricao}`;
                }

                return "";
            }

            return "";
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        handleJustify() {
            if (!this.justify.trim().length) {
                this.isInvalid = true;
                return;
            } else {
                this.isInvalid = false;
                this.$store.commit("batchJustify", { justificativa: this.justify, aprovado: this.localAction === 'approve' });
                this.$emit("close");
            }
        }
    }
};
</script>

<style scoped>
.justify-content {
    margin-top: 20px;
    position: relative;
}

.textarea-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.textarea-wrapper.invalid {
    border: 1px solid red;
}

.textarea-wrapper span {
    font-size: 10px;
    color: #999;
    background-color: #eee;
    width: 100%;
    padding-right: 5px;
    display: block;
    text-align: right;
}

label {
    color: #999;
    font-size: 12px;
}

textarea {
    padding: 10px;
    width: 100%;
    height: 100px;
    background-color: #eee;
    resize: none;
}

textarea:focus {
    outline: none;
    border: none;
}

.btn-footer {
    padding: 8px 16px;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 12px;
}

.btn-footer.primary {
    background-color: #FFAA00;
    color: #fff;
}

.btn-footer.secondary {
    background-color: #E4E4E4;
    color: #000;
}
</style>