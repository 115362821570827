import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import _ from "lodash";
import router from "../router/index";
import Swal from "sweetalert2";

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  state: {
    headerShowMinimal: false,
    routeParams: null,
    loader: true,
    showPage: false,
    data: [],
    dataOriginal: [],
    items: [],
    itemsPaginate: [],
    valorSemImposto: 0,
    valorComImposto: 0,
    loaderOpacity: false,
    loaderCheck: false,
    modalSaveSucess: false,
    modalSendSucess: false,
    modalSend: false,
    permiteVenderUnidade: false,
    vPage: null,
    flagControl: true,
    shouldFilter: false,
    filtros: {
      segmento: {
        label: "Segmento",
        options: [],
      },
      linha: {
        label: "Linha",
        options: [],
      },
      grupo: {
        label: "Grupo",
        options: [],
      },
      subgrupo: {
        label: "Subgrupo",
        options: [],
      },
      descFornecedor: {
        label: "Fabricante",
        options: [],
      },
    },
    filtrosAtivos: {
      segmento: "",
      linha: "",
      grupo: "",
      subgrupo: "",
      descFornecedor: "",
      busca: "",
      bloqueados: false,
    },
    orderItems: "", // "ref" | "desc" | ""
    showFilter: false,
    selectedItems: [],
  },
  mutations: {
    setOrdersItems(state, payload) {
      state.orderItems = payload;
    },
    setHeaderShowMinimal(state, payload) {
      state.headerShowMinimal = payload;
    },
    setItemJustificativa(state, payload) {
      if (payload.add) {
        Vue.set(state.data.items[payload.index], 'justificativa', '');
        Vue.set(state.items[payload.index], 'justificativa', '');
        Vue.set(state.itemsPaginate[payload.index], 'justificativa', '');
      } else {
        Vue.delete(state.data.items[payload.index], 'justificativa');
        Vue.delete(state.items[payload.index], 'justificativa');
        Vue.delete(state.itemsPaginate[payload.index], 'justificativa');
      }
    },
    updateItem(state, payload) {
      const positionItem = state.data.items.findIndex(
        (item) => item.codigo === payload.codigo
      );

      state.data.items[positionItem].segregacoes = _.cloneDeep(
        state.dataOriginal.items[positionItem].segregacoes
      );
    },
    updateVPage(state, payload) {
      state.vPage = payload;
    },
    setItems(state, payload) {
      state.items = payload;

      if (state.selectedItems.length) {
        state.selectedItems = state.selectedItems.filter((code) => state.items.some((item) => item.codigo === code));
      }
    },
    setPermiteVenderUnidade(state, payload) {
      state.permiteVenderUnidade = payload;
    },
    setModalSend(state, payload) {
      state.modalSend = payload;
    },
    setModalSendSucess(state, payload) {
      state.modalSendSucess = payload;
    },
    setModalSaveSucess(state, payload) {
      state.modalSaveSucess = payload;
    },
    setLoaderCheckShow(state) {
      state.loaderCheck = true;
    },
    setLoaderCheckHide(state) {
      state.loaderCheck = false;
    },

    setLoaderOpacityShow(state) {
      state.loaderOpacity = true;
    },
    setLoaderOpacityHide(state) {
      state.loaderOpacity = false;
    },
    setLoaderShow(state) {
      state.loader = true;
    },
    setLoaderHide(state) {
      state.loader = false;
    },
    setShowPage(state) {
      state.showPage = true;
    },
    setHidePage(state) {
      state.showPage = false;
    },

    setItemsPaginate(state, payload) {
      state.itemsPaginate = payload;
    },

    setData(state, payload) {
      state.data = payload;
    },
    setDataOriginal(state, payload) {
      state.dataOriginal = payload;
    },
    setQuantityItem(state) {
      state.valorComImposto = state.data.items
        .map((item) => {
          return item.precoComImpostos * item.quantidade;
        })
        .reduce((acc, news) => acc + news, 0);

      state.valorSemImposto = state.data.items
        .map((item) => {
          return item.preco * item.quantidade;
        })
        .reduce((acc, news) => acc + news, 0);
    },
    setValores(state, payload) {
      state.valorSemImposto = payload.valorSemImposto;
      state.valorComImposto = payload.valorComImposto;
    },
    resetSegregation(state, payload) {
      state.data.items[payload.positionArray].segregacoes =
        state.dataOriginal.items[payload.positionArray].segregacoes;
    },
    eraseAllQuantity(state) {
      for (let count = 0; count < state.data.items.length; count++) {
        if (state.data.items[count].segregacoes.length) {
          state.data.items[count].segregacoes = state.data.items[
            count
          ].segregacoes.map((seg) => ({
            ...seg,
            quantidade: 0,
            volatil: 0,
            action: false,
            actionSegregation: false,
          }));
          state.data.items[count].quantidade = 0;
        } else {
          state.data.items[count].quantidade = 0;
        }
      }

      state.valorComImposto = 0;
      state.valorSemImposto = 0;
    },
    refreshAllQuantity(state) {
      for (let count = 0; count < state.data.items.length; count++) {
        const segregacaoOriginal = _.cloneDeep(
          state.dataOriginal.items[count].segregacoes
        );
        const quantidadeOriginal = _.cloneDeep(
          state.dataOriginal.items[count].quantidadeOriginal
        );

        if (state.data.items[count].segregacoes.length) {
          state.data.items[count].segregacoes = segregacaoOriginal;
          state.data.items[count].quantidade = quantidadeOriginal;
          state.data.items[count].preco = state.data.items[count].precoOriginal;
        } else {
          state.data.items[count].quantidade = quantidadeOriginal;
          state.data.items[count].preco = state.data.items[count].precoOriginal;
        }
      }
      state.valorComImposto = state.data.items
        .map((item) => item.precoComImpostos * item.quantidade)
        .reduce((acc, news) => acc + news, 0);
      state.valorSemImposto = state.data.items
        .map((item) => item.preco * item.quantidade)
        .reduce((acc, news) => acc + news, 0);
    },
    setFiltersArray(state, payload) {
      state.filtros = payload;
    },
    setCurrentFilter(state, payload) {
      state.filtrosAtivos = {...state.filtrosAtivos, ...payload};
    },
    updateSearch(state, payload) {
      state.filtrosAtivos.busca = payload;
    },
    updateFilterBlocked(state, payload) {
      state.filtrosAtivos.bloqueados = payload;
    },
    setShowFilter(state, payload) {
      state.showFilter = payload;
    },
    setSelectedItems(state, payload) {
      if (payload) {
        state.selectedItems = state.items.map((item) => item.codigo);
      } else {
        state.selectedItems = [];
      }
      
    },
    setSelectedItem(state, payload) {
      if (payload.checked) {
        state.selectedItems.push(payload.codigo);
      } else {
        state.selectedItems = state.selectedItems.filter((item) => item !== payload.codigo);
      }
    },
    resetAllItems(state) {
      const originalItems = state.dataOriginal.items;

      state.data.items = state.data.items.map((item) => {
        const originalItem = originalItems.find((original) => original.codigo === item.codigo);

        if (originalItem) {
          return { ...originalItem };
        }

        return item;
      });

      state.items = state.items.map((item) => {
        const originalItem = originalItems.find((original) => original.codigo === item.codigo);

        if (originalItem) {
          return { ...originalItem };
        }

        return item;
      });

      state.itemsPaginate = state.itemsPaginate.map((item) => {
        const originalItem = originalItems.find((original) => original.codigo === item.codigo);

        if (originalItem) {
          return { ...originalItem };
        }

        return item;
      });
    },
    resetItem(state, payload) {
      const originalItem = state.dataOriginal.items.find((item) => item.codigo === payload);

      if (originalItem) {
        state.data.items = state.data.items.map((item) => {
          if (item.codigo === payload) {
            return { ...originalItem };
          }

          return item;
        });

        state.items = state.items.map((item) => {
          if (item.codigo === payload) {
            return { ...originalItem };
          }

          return item;
        });

        state.itemsPaginate = state.itemsPaginate.map((item) => {
          if (item.codigo === payload) {
            return { ...originalItem };
          }

          return item;
        });
      }
    },
    justifyItem(state, payload) {
      state.data.items = state.data.items.map((item) => {
        if (item.codigo === payload.codigo) {
          return {
            ...item,
            justificativa: payload.justificativa,
            aprovado: payload.aprovado,
          };
        }

        return item;
      });

      state.items = state.items.map((item) => {
        if (item.codigo === payload.codigo) {
          return {
            ...item,
            justificativa: payload.justificativa,
            aprovado: payload.aprovado,
          };
        }

        return item;
      });

      state.itemsPaginate = state.itemsPaginate.map((item) => {
        if (item.codigo === payload.codigo) {
          return {
            ...item,
            justificativa: payload.justificativa,
            aprovado: payload.aprovado,
          };
        }

        return item;
      });
    },
    batchJustify(state, payload) {
      state.data.items = state.data.items.map((item) => {
        if (state.selectedItems.includes(item.codigo) && item.hasOwnProperty("justificativa")) {
          return {
            ...item,
            justificativa: payload.justificativa,
            aprovado: payload.aprovado,
          };
        }

        return item;
      });

      state.items = state.items.map((item) => {
        if (state.selectedItems.includes(item.codigo) && item.hasOwnProperty("justificativa")) {
          return {
            ...item,
            justificativa: payload.justificativa,
            aprovado: payload.aprovado,
          };
        }

        return item;
      });

      state.itemsPaginate = state.itemsPaginate.map((item) => {
        if (state.selectedItems.includes(item.codigo) && item.hasOwnProperty("justificativa")) {
          return {
            ...item,
            justificativa: payload.justificativa,
            aprovado: payload.aprovado,
          };
        }

        return item;
      });

      state.selectedItems = [];
    }
  },

  actions: {
    async getData({ commit, state }, data) {
      commit("setLoaderShow");

      try {
        let response = null;

        response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/proposal/client/${data.cpfCnpj}/${data.numPedido}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json; charset=UTF-8",
            },
          }
        );

        let newQuantity = response.data.items.map((item, index) => ({
          quantidadeOriginal: item.quantidade,
          positionArray: index,
          qtdSelecaoSegregacaoMax: item.qtdSelecaoSegregacaoMax
            ? item.qtdSelecaoSegregacaoMax
            : 0,
          ...(item.bloqueado && { justificativa: "" }),
          precoOriginal: item.preco,
          aprovado: false,
          ...item,
        }));

        const filters = _.cloneDeep(state.filtros);
        for (let i = 0; i < response.data.items.length; i++) {
          const item = response.data.items[i];

          for (const propriedade in item.detalhesProduto) {
            if (filters.hasOwnProperty(propriedade)) {
              if (!filters[propriedade].options.includes(item.detalhesProduto[propriedade])) {
                filters[propriedade].options.push(item.detalhesProduto[propriedade]);
              }
            }
          }
        }
        commit("setFiltersArray", filters);


        let newQuantitySegregacao = newQuantity.map((item) => {
       
          let segregacao = item.segregacoes.map((seg) => {
            return {
              ...seg,
              imagem: seg.imagem
                ? seg.imagem
                : require("@/assets/images/placeholder-produto.png"),
              multiplo: seg.multiplo ? seg.multiplo : 0,
              action: seg.quantidade === 0 ? false : true,
              actionSegregation: seg.quantidade === 0 ? false : true,
              volatil: Number(seg.quantidade),
              disabled: false,
            };
          });

          if (item.segregacoes.length) {
            return {
              ...item,
              segregacoes: [...segregacao],
              total: 0,
            };
          } else {
            return {
              ...item,
              segregacoes: [],
              total: 0,
            };
          }
        });

        response.data.items = newQuantitySegregacao;

        state.valorComImposto = response.data.items
          .map((item) => item.precoComImpostos * item.quantidade)
          .reduce((acc, news) => acc + news, 0);
        state.valorSemImposto = response.data.items
          .map((item) => item.preco * item.quantidade)
          .reduce((acc, news) => acc + news, 0);

        commit("setPermiteVenderUnidade", response.data.permiteVenderUnidade);
        commit("setData", response.data);
        state.items = response.data.items;

        commit("setDataOriginal", _.cloneDeep(state.data));

        commit("setLoaderHide");
        commit("setShowPage");
      } catch (error) {
        router.push("/error");
        commit("setLoaderHide");
      }
    },

    async saveAndSendEstimate(state, payload) {
      const {
        cpfCnpj,
        numPedido,
      } = router.currentRoute.params;
      let datas = this.state.data;
      let dataOriginal = this.state.dataOriginal;

      let objectMerge = _.cloneDeep(Object.assign(dataOriginal, datas));

      for (let counter = 0; counter < objectMerge.items.length; counter++) {
        if (objectMerge.items[counter].hasOwnProperty("segregacoesOriginal")) {
          delete objectMerge.items[counter].segregacoesOriginal;
        }

        if (objectMerge.items[counter].hasOwnProperty("quantidadeOriginal")) {
          delete objectMerge.items[counter].quantidadeOriginal;
          delete objectMerge.items[counter].precoOriginal;
        }

        if (objectMerge.items[counter].hasOwnProperty("frontImage")) {
          delete objectMerge.items[counter].frontImage;
        }

        if (objectMerge.items[counter].hasOwnProperty("originalQtde")) {
          delete objectMerge.items[counter].originalQtde;
        }

        if (objectMerge.items[counter].hasOwnProperty("positionArray")) {
          delete objectMerge.items[counter].positionArray;
        }

        if (objectMerge.items[counter].hasOwnProperty("total")) {
          delete objectMerge.items[counter].total;
        }

        if (objectMerge.items[counter].segregacoes.length) {
          for (
            let cont = 0;
            cont < objectMerge.items[counter].segregacoes.length;
            cont++
          ) {
            if (
              objectMerge.items[counter].segregacoes[cont].hasOwnProperty(
                "action"
              )
            ) {
              delete objectMerge.items[counter].segregacoes[cont].action;
            }

            if (
              objectMerge.items[counter].segregacoes[cont].hasOwnProperty(
                "actionSegregation"
              )
            ) {
              delete objectMerge.items[counter].segregacoes[cont]
                .actionSegregation;
            }

            if (
              objectMerge.items[counter].segregacoes[cont].hasOwnProperty(
                "originalQtde"
              )
            ) {
              delete objectMerge.items[counter].segregacoes[cont].originalQtde;
            }

            if (
              objectMerge.items[counter].segregacoes[cont].hasOwnProperty(
                "volatil"
              )
            ) {
              delete objectMerge.items[counter].segregacoes[cont].volatil;
            }
          }
        }
      }

      try {
        const ipRequest = await axios.get('https://api.ipify.org?format=json');

        objectMerge["ip"] = ipRequest.data.ip;
        objectMerge["dataEnvio"] = new Date().toLocaleString();
      } catch (err) {
        Vue.toast(`Error ! ${err.message}`, {
          id: "my-toast",

          className: ["my-toast", "toast-warning"],
          horizontalPosition: "center",
          verticalPosition: "top",
          duration: 3500,
          mode: "queue",
          transition: "fade",
          closeable: false,
        });
      }

      if (payload === "save") {
        this.commit("setLoaderOpacityShow");

        try {
          let response = null;

          response = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/proposal/app/${cpfCnpj}/${numPedido}`,
            objectMerge
          );

          if (
            response.data === "Import success" ||
            response.data === "Successfully imported"
          ) {
            const toSave = _.cloneDeep(state.state.data);
            for (let count = 0; count < toSave.items.length; count++) {
              toSave.items[count].quantidadeOriginal =
                toSave.items[count].quantidade;
            }

            state.state.dataOriginal.items = _.cloneDeep(toSave.items);
            state.state.data.items = _.cloneDeep(toSave.items);

            this.commit("setLoaderOpacityHide");
            Vue.toast("Proposta salva com sucesso", {
              id: "my-toast",

              className: ["my-toast", "green"],
              horizontalPosition: "center",
              verticalPosition: "top",
              duration: 3500,
              mode: "queue",
              transition: "fade",
              closeable: false,
            });
          } else {
            this.commit("setLoaderOpacityHide");
            Vue.toast(`Error ! ${response.data}`, {
              id: "my-toast",

              className: ["my-toast", "toast-warning"],
              horizontalPosition: "center",
              verticalPosition: "top",
              duration: 3500,
              mode: "queue",
              transition: "fade",
              closeable: false,
            });
          }
        } catch (error) {
          this.commit("setLoaderOpacityHide");
          Vue.toast(`Error ! ${error}`, {
            id: "my-toast",

            className: ["my-toast", "toast-warning"],
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 3500,
            mode: "queue",
            transition: "fade",
            closeable: false,
          });
        }
      }

      if (payload === "send") {
        this.commit("setLoaderOpacityShow");

        try {
          let response = null;

          response = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/proposal/client/${cpfCnpj}/${numPedido}`,
            objectMerge
          );

          if (
            response.data === "Import success" ||
            response.data === "Successfully imported"
          ) {
            this.commit("setLoaderOpacityHide");
            this.commit("setModalSendSucess", true);
          } else {
            this.commit("setLoaderOpacityHide");
            Swal.fire({
              title: "Error!",
              text: response.data,
              icon: "error",
              confirmButtonText: "Fechar",
            });
          }
        } catch (error) {
          this.commit("setLoaderOpacityHide");

          Swal.fire({
            title: "Error!",
            text: error,
            icon: "error",
            confirmButtonText: "Fechar",
          });
        }
      }
    },
  },
  getters: {
    getBlocketItemsCount(state) {
      return state.items.filter((item) => item.bloqueado).length;
    },
    getIsAllSelectedItems(state) {
      return state.items.length === state.selectedItems.length;
    },
    getIsIndeterminate(state, getters) {
      return state.selectedItems.length > 0 && !getters.getIsAllSelectedItems;
    },
    getItems(state) {
      return state.data.items;
    },
    casasDecimais(state) {
      return state.data.casasDecimais ?? 2;
    },
    totalFooter(state, getters) {
      const casasDecimais = getters.casasDecimais;

      return state.data.items.reduce((acc, curr) => {
        return acc += Number(curr.preco.toFixed(casasDecimais)) * curr.quantidade;
      }, 0)
    },
    totalComImpostosFooter(state, getters) {
      const casasDecimais = getters.casasDecimais;

      return state.data.items.reduce((acc, curr) => {
        return acc += curr.precoComImpostos.toFixed(casasDecimais) * curr.quantidade;
      }, 0)
    },
    total(state) {
      return state.data.items.reduce((acc, curr) => {
        return acc += Number(curr.preco.toFixed(2)) * curr.quantidade;
      }, 0)
    },
    totalComImpostos(state) {
      return state.data.items.reduce((acc, curr) => {
        return acc += curr.precoComImpostos.toFixed(2) * curr.quantidade;
      }, 0)
    },
    isValidOrder(state) {
      let valid = null;
      const items = state.data.items.filter(item => item.hasOwnProperty('justificativa'));

      for (let i = 0; i < items.length; i++) {
        if (!items[i].justificativa) {
          valid = false;
        }
      }

      if (valid === null) valid = true;

      return valid;
    },
    hasJustificativa: (state) => (codigo) => {
      const hasJustificativa = state.data.items.find((item) => item.codigo === codigo);
      return hasJustificativa?.hasOwnProperty("justificativa")  ?? false
    },
    totalVrVenda: (state, getters) => {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrVenda(curr);

        return acc += val
      }, 0);

      return value;
    },
    vrVenda: (state) => (item) => {
      return Number((Number(item.preco.toFixed(2)) * item.quantidade).toFixed(2));
    },
    totalVrLucroBruto(state, getters) {
      return Number((getters.totalVrVenda - getters.totalVrImpostos).toFixed(2));
    },
    vrLucroBruto: (state, getters) => (item) => {
      const vrVenda = getters.vrVenda(item);
      const vrImpostos = getters.vrImpostos(item);
      return Number((vrVenda - vrImpostos).toFixed(2));
    },
    totalVrComissao(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrComissao(curr);

        return acc += val;
      }, 0);
      
      return value;
    },
    vrComissao: (state, getters) => (item) => {
      const vrLucroBruto = getters.vrLucroBruto(item);
      return vrLucroBruto * (item.comissao / 100);
    },
    totalVrBaseIpi(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrBaseIpi(curr);

        return acc += val;
      }, 0);
      
      return value;
    },
    vrBaseIpi: (state, getters) => (item) => {
      const totalVenda = getters.vrVenda(item);
      return Number((totalVenda * (item.fidelidade / 100)).toFixed(2));
    },
    totalVrIpiComporIcms(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrIpiComporIcms(curr);

        return acc += val;
      }, 0);
      
      return value;
    },
    vrIpiComporIcms: (state, getters) => (item) => {
      const vrBaseIpi = getters.vrBaseIpi(item);
      return Number(((vrBaseIpi * item.ipi) / 100).toFixed(2));
    },
    totalVrPercentual(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrPercentual(curr);

        return acc += val;
      }, 0);
      
      return value;
    },
    vrPercentual: (state) => (item) => {
      return Number(((item.perc / 100) * Number(item.preco.toFixed(2))).toFixed(6));
    },
    totalVrFreteNaoEmbute(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrFreteNaoEmbute(curr);

        return acc += val;
      }, 0);
      
      return value;
    },
    vrFreteNaoEmbute: (state, getters) => (item) => {
      const vrPercentual = getters.vrPercentual(item);
      return item.isFreteEmbute ? !item.isFreteM3 ? Number((vrPercentual / 100).toFixed(6)) : item.rVrMetroCubico : 0;
    },
    totalVrFreteEmbute(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrFreteEmbute(curr);

        return acc += val;
      }, 0);
      
      return value;
    },
    vrFreteEmbute: (state, getters) => (item) => {
      const vrIcmsSuframa = getters.vrIcmsSuframa(item);
      return (item.isFreteEmbute ? !item.isFreteM3 ? Number(((Number(item.preco.toFixed(2)) - vrIcmsSuframa) * (item.perc / 100)).toFixed(6)) : Number(item.rVrMetroCubico.toFixed(6)) : 0) * item.quantidade;
    },
    totalVrBaseIcms(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrBaseIcms(curr);
        
        return acc += val;
      }, 0);
      
      return value;
    },
    vrBaseIcms: (state, getters) => (item) => {
      const totalVenda = getters.vrVenda(item);
      const vrIpiComporIcms = getters.vrIpiComporIcms(item);
      const vrIpi = item.consideraIPI ? vrIpiComporIcms : 0;
      const vrFreteNaoEmbute = getters.vrFreteNaoEmbute(item);

      return Number((vrIpi + ((totalVenda + vrFreteNaoEmbute) * item.fidelidade / 100)).toFixed(2));
    },
    totalVrIcms(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = getters.vrIcms(curr);
        
        return acc += val;
      }, 0);
      
      return value;
    },
    vrIcms: (state, getters) => (item) => {
      const vrBaseIcms = getters.vrBaseIcms(item);
      return item.isSuframa ? 0 : Number((vrBaseIcms * (item.icms / 100)).toFixed(2));
    },
    totalVrIcmsSuframa(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const vrBaseIcms = getters.vrBaseIcms(curr);
        const val = curr.isSuframa ? Number((vrBaseIcms * (curr.icms / 100)).toFixed(2)) : 0;

        return acc += val;
      }, 0);
      
      return value;
    },
    vrIcmsSuframa: (state, getters) => (item) => {
      const vrBaseIcms = getters.vrBaseIcms(item);
      return item.isSuframa ? Number((vrBaseIcms * (item.icms / 100)).toFixed(2)) : 0;
    },
    totalVrFcp(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const vrBaseIcms = getters.vrBaseIcms(curr);
        const val = Number((curr.retiraFcpDifalBasePisCofins ? vrBaseIcms * curr.fcp / 100 : 0).toFixed(2));

        return acc += val;
      }, 0);
      
      return value;
    },
    vrFcp: (state, getters) => (item) => {
      const vrBaseIcms = getters.vrBaseIcms(item);
      return Number((item.retiraFcpDifalBasePisCofins ? vrBaseIcms * item.fcp / 100 : 0).toFixed(2));
    },
    totalVrDifal(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const vrBaseIcms = getters.vrBaseIcms(curr);
        const val = Number((curr.retiraDifalBaseCofins ? vrBaseIcms * curr.difal / 100 : 0).toFixed(2));

        return acc += val;
      }, 0);
      
      return value;
    },
    vrDifal: (state, getters) => (item) => {
      const vrBaseIcms = getters.vrBaseIcms(item);
      return Number((item.retiraDifalBaseCofins ? vrBaseIcms * item.difal / 100 : 0).toFixed(2));
    },
    totalVrBasePis(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const totalVenda = getters.vrVenda(curr);
        const vrIcms = getters.vrIcms(curr);
        const vrIpiComporIcms = getters.vrIpiComporIcms(curr);
        const vrFreteNaoEmbute = getters.vrFreteNaoEmbute(curr);
        const vrFcp = getters.vrFcp(curr);
        const vrDifal = getters.vrDifal(curr);

        const vrRetiraICMS = curr.isRetiraICMS ? vrIcms : 0;
        const vrFrete = !curr.isFreteM3 ? vrIpiComporIcms : 0;
        const val = Number(((totalVenda + vrFreteNaoEmbute) * (curr.fidelidade / 100) - vrRetiraICMS + vrFrete - vrFcp - vrDifal).toFixed(2));

        return acc += val;
      }, 0);
      
      return value;
    },
    vrBasePis: (state, getters) => (item) => {
      const totalVenda = getters.vrVenda(item);
      const vrIcms = getters.vrIcms(item);
      const vrIpiComporIcms = getters.vrIpiComporIcms(item);
      const vrFreteNaoEmbute = getters.vrFreteNaoEmbute(item);
      const vrFcp = getters.vrFcp(item);
      const vrDifal = getters.vrDifal(item);

      const vrRetiraICMS = item.isRetiraICMS ? vrIcms : 0;
      const vrFrete = !item.isFreteM3 ? vrIpiComporIcms : 0;
      return Number(((totalVenda + vrFreteNaoEmbute) * (item.fidelidade / 100) - vrRetiraICMS + vrFrete - vrFcp - vrDifal).toFixed(2));
    },
    totalVrBaseCofins(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const totalVenda = getters.vrVenda(curr);
        const vrFreteNaoEmbute = getters.vrFreteNaoEmbute(curr);
        const vrIcms = getters.vrIcms(curr);
        const vrIpiComporIcms = getters.vrIpiComporIcms(curr);
        const vrFcp = getters.vrFcp(curr);
        const vrDifal = getters.vrDifal(curr);

        const val = Number((((totalVenda + vrFreteNaoEmbute) * curr.fidelidade / 100) - (curr.isRetiraICMS ? vrIcms : 0) + (curr.incluiIPIBaseCofins ? vrIpiComporIcms : 0) - vrFcp - vrDifal).toFixed(2));

        return acc += val;
      }, 0);
      
      return value;
    },
    vrBaseCofins: (state, getters) => (item) => {
      const totalVenda = getters.vrVenda(item);
      const vrFreteNaoEmbute = getters.vrFreteNaoEmbute(item);
      const vrIcms = getters.vrIcms(item);
      const vrIpiComporIcms = getters.vrIpiComporIcms(item);
      const vrFcp = getters.vrFcp(item);
      const vrDifal = getters.vrDifal(item);

      return Number((((totalVenda + vrFreteNaoEmbute) * item.fidelidade / 100) - (item.isRetiraICMS ? vrIcms : 0) + (item.incluiIPIBaseCofins ? vrIpiComporIcms : 0) - vrFcp - vrDifal).toFixed(2));
    },
    totalVrPis(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const vrBasePis = getters.vrBasePis(curr);
        const val = Number((vrBasePis * (curr.pis / 100)).toFixed(2));

        return acc += val;
      }, 0);
      
      return value;
    },
    vrPis: (state, getters) => (item) => {
      const vrBasePis = getters.vrBasePis(item);
      return Number((vrBasePis * (item.pis / 100)).toFixed(2));
    },
    totalVrCofins(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const vrBaseCofins = getters.vrBaseCofins(curr);
        const val = Number((((vrBaseCofins * curr.cofins) / 100)).toFixed(2));

        return acc += val;
      }, 0);
      
      return value;
    },
    vrCofins: (state, getters) => (item) => {
      const vrBaseCofins = getters.vrBaseCofins(item);
      return Number((((vrBaseCofins * item.cofins) / 100)).toFixed(2));
    },
    totalVrImpostos(state, getters) {
      const val = Number((getters.totalVrIcms + getters.totalVrIcmsSuframa + getters.totalVrPis + getters.totalVrCofins).toFixed(2));

      return val;
    },
    vrImpostos: (state, getters) => (item) => {
      const vrIcms = getters.vrIcms(item);
      const vrIcmsSuframa = getters.vrIcmsSuframa(item);
      const vrPis = getters.vrPis(item);
      const vrCofins = getters.vrCofins(item);

      return Number((vrIcms + vrIcmsSuframa + vrPis + vrCofins).toFixed(2));
    },
    totalVrDespesaOperacional(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const totalVenda = getters.vrVenda(curr);
        const vrIcmsSuframa = getters.vrIcmsSuframa(curr);
        const val = Number(((totalVenda - vrIcmsSuframa) * ((curr.despesaOperacional + curr.acrescimoNaDespesa) / 100)).toFixed(6));

        return acc += val;
      }, 0);
      
      return value;
    },
    vrDespesaOperacional: (state, getters) => (item) => {
      const totalVenda = getters.vrVenda(item);
      const vrIcmsSuframa = getters.vrIcmsSuframa(item);
      return Number(((totalVenda - vrIcmsSuframa) * ((item.despesaOperacional + item.acrescimoNaDespesa) / 100)).toFixed(6));
    },
    totalVrLucroOperacional(state, getters) {
      return getters.totalVrLucroBruto - getters.totalVrComissao - getters.totalVrFreteEmbute - getters.totalVrDespesaOperacional;
    },
    vrLucroOperacional: (state, getters) => (item) => {
      const vrLucroBruto = getters.vrLucroBruto(item);
      const vrComissao = getters.vrComissao(item);
      const vrFreteEmbute = getters.vrFreteEmbute(item);
      const vrDespesaOperacional = getters.vrDespesaOperacional(item);

      return vrLucroBruto - vrComissao - vrFreteEmbute - vrDespesaOperacional;
    },
    totalVrDescontoContrato(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const totalVenda = getters.vrVenda(curr);
        const vrIpiComporIcms = getters.vrIpiComporIcms(curr);
        const val = Number((((totalVenda + (vrIpiComporIcms * curr.quantidade) + (curr.sT * curr.quantidade)) * curr.descCliente) / 100).toFixed(2));

        return acc += val;
      }, 0);
      
      return value;
    },
    totalVrCustoTotal(state) {
      const value = state.data.items.reduce((acc, curr) => {
        const val = Number((curr.precoCusto * curr.quantidade).toFixed(6));

        return acc += val;
      }, 0);
      
      return value;
    },
    vrCustoTotal: (state) => (item) => {
      return Number((item.precoCusto * item.quantidade).toFixed(6));
    },
    totalVrLucroLiquido(state, getters) {
      return getters.totalVrLucroOperacional - getters.totalVrCustoTotal;
    },
    vrLucroLiquido: (state, getters) => (item) => {
      const vrLucroOperacional = getters.vrLucroOperacional(item);
      const vrCustoTotal = getters.vrCustoTotal(item);

      return vrLucroOperacional - vrCustoTotal;
    },
    totalVrIR(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const vrLucroLiquido = getters.vrLucroLiquido(curr);
        const val = vrLucroLiquido * (curr.iR / 100);

        return acc += val;
      }, 0);
      
      return value;
    },
    vrIR: (state, getters) => (item) => {
      const vrLucroLiquido = getters.vrLucroLiquido(item);
      return vrLucroLiquido * (item.iR / 100);
    },
    totalVrCSLL(state, getters) {
      const value = state.data.items.reduce((acc, curr) => {
        const vrLucroLiquido = getters.vrLucroLiquido(curr);
        const val = vrLucroLiquido * (curr.cSLL / 100);

        return acc += val;
      }, 0);
      
      return value;
    },
    vrCSLL: (state, getters) => (item) => {
      const vrLucroLiquido = getters.vrLucroLiquido(item);
      return vrLucroLiquido * (item.cSLL / 100);
    },
    totalVrMargem(state, getters) {
      return getters.totalVrLucroLiquido - getters.totalVrIR - getters.totalVrCSLL;
    },
    vrMargem: (state, getters) => (item) => {
      const vrLucroLiquido = getters.vrLucroLiquido(item);
      const vrIR = getters.vrIR(item);
      const vrCSLL = getters.vrCSLL(item);

      return vrLucroLiquido - vrIR - vrCSLL;
    },
    totalPercMargem(state, getters) {
      const margemFinal = [];
      
      const value = state.data.items.reduce((acc, curr) => {
        const indiceOperacional = state.data.indiceOperacional;
        const vrBase = Number(curr.preco.toFixed(2)) * curr.quantidade;
        let margem = vrBase;
        let ir = JSON.parse(JSON.stringify(curr.iR));
        let csll = JSON.parse(JSON.stringify(curr.cSLL));
        const freteUnitario = getters.vrFreteEmbute(curr);
        const impostos = getters.vrImpostos(curr);

        const descontoContrato = Number(((vrBase * curr.descCliente) / 100).toFixed(2));
        const valorBonificacao = ((getters.vrCustoTotal(curr) * (curr.perBonificacao / 100)) / (1 - ((impostos + (getters.vrIpiComporIcms(curr) * curr.quantidade)) / vrBase)));
        const vrBaseComissao = Number((vrBase - (curr.descontaComissao === "S" ? descontoContrato : 0) - (curr.freteEmbuteOuNaoDestaca ? Number((freteUnitario).toFixed(6)) : 0) - getters.vrImpostos(curr)).toFixed(2));
        
        margem -= descontoContrato;
        margem -= impostos;
        margem -= getters.vrDespesaOperacional(curr);
        margem -= valorBonificacao;
        margem -= Number((vrBaseComissao * (curr.comissao / 100)).toFixed(4));
        margem -= Number((freteUnitario).toFixed(6));

        //const lucroAntesIR = margem - this.vrCustoTotal;

        margem -= getters.vrCustoTotal(curr);

        //const margemComissao = lucroAntesIR;
        const margemFidelidade = Number((margem * (curr.fidelidade / 100)).toFixed(6));

        if (curr.lucroPresumidoIR > 0) {
          ir = Number(((curr.iR * curr.lucroPresumidoIR) / 100).toFixed(6));
          ir = Number(((margemFidelidade * ir) / 100).toFixed(6));
        } else {
          ir = Number(((margemFidelidade * curr.iR) / 100).toFixed(6));
        }

        if (curr.lucroPresumidoCSLL > 0) {
          csll = Number(((curr.cSLL * curr.lucroPresumidoCSLL) / 100).toFixed(6));
          csll = Number(((margemFidelidade * csll) / 100).toFixed(6));
        } else {
          csll = Number(((margemFidelidade * curr.cSLL) / 100).toFixed(6));
        }

        //const margemSemIrCsll = margem;
        margem = Number((margem - ir - csll).toFixed(6));

        margemFinal.push({ margem, vrBase });

        acc += margem /= vrBase;
        
        const val = Number(((margem * 100) * indiceOperacional).toFixed(4));

        return acc += val;
      }, 0);

      
      const mFinal = margemFinal.reduce((acc, curr) => {
        const { margem } = curr;

        return acc += margem;
      }, 0);

      const baseFinal = margemFinal.reduce((acc, curr) => {
        const { vrBase } = curr;

        return acc += vrBase;
      }, 0);
      
      const m = mFinal / baseFinal;
      return Number((m * 100 * state.data.indiceOperacional).toFixed(4));
    },
    percMargem: (state, getters) => (item) => {
      const indiceOperacional = state.data.indiceOperacional;
      const vrBase = Number(item.preco.toFixed(2)) * item.quantidade;
      let margem = vrBase;
      let ir = JSON.parse(JSON.stringify(item.iR));
      let csll = JSON.parse(JSON.stringify(item.cSLL));
      const freteUnitario = getters.vrFreteEmbute(item);
      const impostos = getters.vrImpostos(item);

      const descontoContrato = Number(((vrBase * item.descCliente) / 100).toFixed(2));
      const valorBonificacao = ((getters.vrCustoTotal(item) * (item.perBonificacao / 100)) / (1 - ((impostos + (getters.vrIpiComporIcms(item) * item.quantidade)) / vrBase)));
      const vrBaseComissao = Number((vrBase - (item.descontaComissao === "S" ? descontoContrato : 0) - (item.freteEmbuteOuNaoDestaca ? Number((freteUnitario).toFixed(6)) : 0) - getters.vrImpostos(item)).toFixed(2));
      
      margem -= descontoContrato;
      margem -= impostos;
      margem -= getters.vrDespesaOperacional(item);
      margem -= valorBonificacao;
      margem -= Number((vrBaseComissao * item.comissao / 100).toFixed(4));
      margem -= Number((freteUnitario).toFixed(6));

      //const lucroAntesIR = margem - this.vrCustoTotal;

      margem -= getters.vrCustoTotal(item);

      //const margemComissao = lucroAntesIR;
      const margemFidelidade = Number((margem * (item.fidelidade / 100)).toFixed(6));

      if (item.lucroPresumidoIR > 0) {
        ir = Number(((item.iR * item.lucroPresumidoIR) / 100).toFixed(6));
        ir = Number(((margemFidelidade * ir) / 100).toFixed(6));
      } else {
        ir = Number(((margemFidelidade * item.iR) / 100).toFixed(6));
      }

      if (item.lucroPresumidoCSLL > 0) {
        csll = Number(((item.cSLL * item.lucroPresumidoCSLL) / 100).toFixed(6));
        csll = Number(((margemFidelidade * csll) / 100).toFixed(6));
      } else {
        csll = Number(((margemFidelidade * item.cSLL) / 100).toFixed(6));
      }

      //const margemSemIrCsll = margem;
      margem = Number((margem - ir - csll).toFixed(6));

      margem /= vrBase;
      
      return Number(((margem * 100) * indiceOperacional).toFixed(4));
    },
    totalQuantidade(state) {
      return state.data.items.reduce((acc, curr) => {
        const quantidade = curr.quantidade;

        return acc += quantidade;
      }, 0);
    },
    shouldRenderMargin(state) {
      return state.data.items[0].hasOwnProperty("cSLL");
    }
  },
  modules: {},
});
