<template>
  <div class="modal">
    <div class="modal__box">
      <div class="modal__content">
        <div class="row">
          <p>(=) Preço na venda</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrVenda) }}</span>
        </div>

        <div class="row">
          <p>(-) Impostos</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrImpostos) }}</span>
        </div>

        <div class="row">
          <p>(=) Lucro bruto</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrLucroBruto) }}</span>
        </div>

        <div class="row">
          <p>(-) Comissão</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrComissao) }}</span>
        </div>

        <div class="row">
          <p>(-) Frete</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(Number((totalVrFreteEmbute).toFixed(6))) }}</span>
        </div>

        <div class="row">
          <p>(-) Despesas operacionais</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrDespesaOperacional) }}</span>
        </div>

        <div class="row">
          <p>(=) Lucro operacional</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrLucroOperacional) }}</span>
        </div>

        <div class="row">
          <p>(-) Custo total</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrCustoTotal) }}</span>
        </div>

        <div class="row">
          <p>(=) Lucro líquido</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrLucroLiquido) }}</span>
        </div>

        <div class="row">
          <p>(-) IR</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrIR) }}</span>
        </div>

        <div class="row">
          <p>(-) CSLL</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrCSLL) }}</span>
        </div>

        <div class="row">
          <p>(=) Margem contribuição</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalVrMargem) }}</span>
        </div>

        <div class="row">
          <p>% Contribuição</p>
          <span>{{ new Intl.NumberFormat("pt-br", {
            style: "decimal",
            currency: "BRL",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            roundingMode: "floor",
          }).format(totalPercMargem) }}</span>
        </div>
      </div>

      <div class="modal__footer">
        <button @click="close">Fechar</button>
      </div>
    </div>
    <div class="modal__overlay"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalMCTotal",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["totalQuantidade", "totalVrVenda", "totalVrImpostos", "totalVrLucroBruto", "totalVrComissao", "totalVrFreteEmbute", "totalVrDespesaOperacional", "totalVrLucroOperacional", "totalVrCustoTotal", "totalVrLucroLiquido", "totalVrIR", "totalVrCSLL", "totalVrMargem", "totalPercMargem"]),
  },
  methods: {
    close() {
      this.$emit("close", false);
    }
  }
}
</script>

<style>
.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1060;
}

.modal .modal__box {
  width: 90%;
  max-width: 300px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  z-index: 1060;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
}

.modal .modal__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1059;
  backdrop-filter: blur(4px);
}

.modal .row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 3px;
}

.modal .row:nth-child(even) {
  background-color: #eee;
}

.modal .row:last-child {
  font-weight: bold;
}

.modal .modal__footer {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.card__field label {
  font-size: 13px;
  color: #757575;
  text-transform: uppercase;
}

.card__field input {
  height: 40px;
  border: 1px solid #eee;
  padding: 10px;
}

.card__field span {
  font-size: 11px;
  color: #757575;
  font-style: italic;
}

.card__field {
  display: flex;
  flex-direction: column;
}

.modal button {
  padding: 8px 20px;
  text-transform: uppercase;
  background-color: #3c8a42;
  color: #fff;
  font-weight: bold;
  transition: background-color .2s;
  flex: 1 auto;
}

.modal button:first-child {
  border: 1px solid #3c8a42;
  background-color: #fff;
  color: #3c8a42;
}

.modal button:first-child:hover {
  background-color: #f1f1f1;
}

.modal button:hover:not(:first-child) {
  background-color: #2c6030;
}

.code_wrapper {
  text-align: center;
  margin-top: 20px;
}

.code_wrapper hr {
  margin-bottom: 20px;
}

.code_wrapper img {
  display: block;
  margin: 0 auto;
}

.code_wrapper p {
  font-size: 12px;
  color: #757575;
}
</style>