<template>
  <div :show="showModal"></div>
</template>

<script>
export default {
  name: "ModalSaveSucess",

  computed: {
    showModal() {
      return this.$store.state.modalSaveSucess
    }
  },
  watch: {
    showModal(news, old) {
      if (news === true) {
        this.$swal({
          icon: 'success',
          title: 'Proposta salva com sucesso',
          confirmButtonColor: " #169840",
          focusConfirm: false
        });
        this.$store.commit("setModalSaveSucess", false)
      }

    }
  }
}
</script>
<style>
.swal2-styled:focus {
  box-shadow: none;
}
</style>

<style  scoped>
.btnCancel,
.btnSend {
  border-radius: 3px;
  color: #fff;
  font-family: "Roboto";
  font-size: 18px;
  margin: 0 10px;
  padding: 10px 15px;
}
.btnSend {
  background: #169840;
}
.btnCancel {
  background: #e4e4e4 !important;
}
.orange {
  color: #ff7300a8 !important;
}
.icon {
  font-size: 120px !important;
}
.modalHeader {
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: center;
  width: 100%;
}
.modalBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.buttonGroup {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}
.modalTitle {
  color: #595959;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
}
.modalSubTitle {
  color: #7e7e7e;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  width: 75vw;
}
</style>