<template>
  <div class="bottomBar">
    <div class="bottomBarContent">
      <div v-if="shouldRenderMargin" class="valuesContainer comImposto clickable" title="clique para acessar os detalhes da margem geral" @click="isModalOpen = true">
        <span class="title">% MC Total <md-icon style="font-size: 19px!important;">unfold_more</md-icon></span>
        <span class="valueCash">{{
          isNaN(totalPercMargem) ? "??" : new Intl.NumberFormat("pt-br", {
                style: "decimal",
                currency: "BRL",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                roundingMode: "floor",
              }).format(totalPercMargem)
        }} %</span>
      </div>
      
      <div class="valuesContainer comImposto">
        <span class="title">Total s/impostos:</span>
        <span class="valueCash">{{
          new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL",
            currencyDisplay: "symbol",
          }).format(totalFooter)
        }}</span>
      </div>

      <div class="valuesContainer semImposto">
        <span class="title">Total aprox. c/impostos:</span>
        <span class="valueCash">{{
          new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL",
          }).format(totalComImpostosFooter)
        }}</span>
      </div>

      <md-button
        class="bottomBarActions save"
        @click="() => this.$store.dispatch('saveAndSendEstimate', 'save')"
      >
        <span class="hideNearTheMobile">Salvar e continuar depois</span>
        <span class="showNearTheMobile"> salvar</span>
      </md-button>

      <md-button
        class="bottomBarActions send"
        @click="() => handleSend()"
      >
        <span class="hideNearTheMobile">Enviar proposta</span>
        <span class="showNearTheMobile">Enviar</span>
      </md-button>
    </div>

    <ModalMCTotal v-if="isModalOpen" @close="isModalOpen = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ModalMCTotal from "../Modals/ModalMCTotal.vue";

export default {
  name: "BottomBar",
  components: {
    ModalMCTotal,
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  methods: {
    handleSend() {
      if (this.isValidOrder) {
        this.$store.commit('setModalSend', true);
      } else {
        this.$swal({
          icon: 'error',
          title: 'Atenção',
          text: "Verifique produtos sem justificativa",
          confirmButtonColor: "#169840",
          focusConfirm: false,
        })
      }
    }
  },
  computed: {
    ...mapGetters(['totalFooter', 'totalComImpostosFooter', 'isValidOrder', 'totalPercMargem', 'shouldRenderMargin']),
    semImposto() {
      return this.$store.state.valorSemImposto;
    },
    comImposto() {
      return this.$store.state.valorComImposto;
    },
  },
};
</script>

<style scoped>
.bottomBar {
  align-items: center;
  background: #f7f7f7;
  bottom: 0;
  box-shadow: #00000033 0px -2px 4px;
  display: flex;
  height: 60px;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1050;
  padding: 0 10px;
}
.bottomBarContent {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  max-width: 1130px;
  width: 100%;
}
.bottomBarActions.save {
  background: #3e6e31;
  color: #ffffff;
  font-size: 13px;
  margin: 0 2px;
}
.bottomBarActions.send {
  background: #169840;
  color: #ffffff;
  font-size: 13px;
  margin: 0 2px;
}
.bottomBarActions {
  padding: 12px 22px;
  align-items: center;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  font-family: "Roboto", sans-serif;
  height: 40px;
  justify-content: space-around;
  text-transform: uppercase;
}
.valuesContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  text-align: right;
}
.valuesContainer.comImposto {
  color: #707070;
  font-family: "Roboto", sans-serif;
}
.valuesContainer.comImposto.clickable {
  cursor: pointer;
  background-color: rgb(230, 230, 230);
  padding: 4px 6px;
  border-radius: 0.275rem;
}
.valuesContainer.semImposto {
  color: #17100d;
  font-family: "Roboto", sans-serif;
}
.valuesContainer > .title {
}
.valuesContainer > .valueCash {
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .bottomBar {
    display: none;
  }
}
</style>
