<template>
  <div class="bottomBarMobile">
    <div class="values left">
      <div v-if="shouldRenderMargin" class="mcContainer" @click="isModalOpen = true">
        <span class="descriptionOfValue">% MC</span>
        <span class="valueNumber">{{
          isNaN(totalPercMargem) ? "??" : new Intl.NumberFormat("pt-br", {
                style: "decimal",
                currency: "BRL",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                roundingMode: "floor",
              }).format(totalPercMargem)
        }} %</span>
      </div>

      <div style="flex: 0.6; text-align: end;">
        <span class="descriptionOfValue">Total s/impostos:</span>
        <span class="valueNumber">
          {{ new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL",
            currencyDisplay: "symbol",
          }).format(totalFooter) }}
        </span>
      </div>
    </div>

    <div class="values rigth">
      <span class="descriptionOfValue">Total c/impostos:</span>
      <span class="valueNumber">{{
        new Intl.NumberFormat("pt-br", {
          style: "currency",
          currency: "BRL",
        }).format(totalComImpostosFooter)
      }}</span>
    </div>

    <ModalMCTotal v-if="isModalOpen" @close="isModalOpen = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalMCTotal from "../Modals/ModalMCTotal.vue";

export default {
    name: "bottomBarMobile",
    data() {
      return {
        isModalOpen: false,
      }
    },
    computed: {
        ...mapGetters(['totalFooter', 'totalComImpostosFooter', "totalPercMargem", "shouldRenderMargin"]),
        semImposto() {
            return this.$store.state.valorSemImposto;
        },
        comImposto() {
            return this.$store.state.valorComImposto;
        },
    },
    components: { ModalMCTotal }
};
</script>

<style scoped>
.bottomBarMobile {
  display: none;
}
@media (max-width: 768px) {
  .bottomBarMobile {
    background: #d31b1b;
    bottom: 0;
    display: flex;
    height: 56px;
    left: 0;
    position: fixed;
    width: 100vw;
    z-index: 1050;
  }
  .values {
    align-items: flex-end;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    width: 50vw;
    overflow-wrap: anywhere;
  }
  .descriptionOfValue {
    font-family: "Roboto", sans-serif;
    font-size: 11px;
  }

  .mcContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0.4;
    border: 1px solid white;
    border-radius: 0.625rem;
  }
  .valueNumber {
    font-size: 16px;
    font-weight: 100;
  }
  .left {
    display: flex;
    gap: 5px;
    flex-direction: row;
    background: #3e6e31;
  }
  .rigth {
    background: #169840;
  }
}
</style>
