<template>
    <div class="modal__footer">
        <slot />
    </div>
</template>

<script>
export default {
    name: "ModalFooter",
}
</script>

<style scoped>
.modal__footer {
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
</style>