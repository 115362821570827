<template>
  <div class="modal">
    <div class="modal__box">
      <div class="modal__content">
        <div class="card__field">
          <label>CPF</label>
          <input type="text" :value="cpf" @input="event => cpf = event.target.value" />
          <span>Apenas números</span>
        </div>

        <div class="code_wrapper" v-if="url">
          <hr />

          <img :src="url" alt="qrcode" />

          <p>Acesse seu aplicativo de autenticador para ler o QR Code acima</p>
        </div>
      </div>

      <div class="modal__footer">
        <button @click="close">Fechar</button>
        <button @click="generateQRCode">Gerar QR Code</button>
      </div>
    </div>
    <div class="modal__overlay"></div>
  </div>
</template>

<script>
import axios from "axios";

const BASE_PROD = "https://orcamento.guaraniafv.com.br:7000";

export default {
  name: "ModalAutenticador",
  data() {
    return {
      cpf: "",
      url: "",
    };
  },
  methods: {
    async generateQRCode() {
      if (this.cpf) {
        try {
          const response = await axios.post(`${BASE_PROD}/2fa/generate`, { cpf: this.cpf.replace(/\D/g, '') });

          this.url = response.data.qr;
        } catch (err) {
          this.$swal({
            icon: 'error',
            title: 'Atenção',
            text: "Não foi possível gerar o QR Code no momento",
            confirmButtonColor: "#169840",
            focusConfirm: false,
          });
        }
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: "É preciso fornecer um CPF válido",
          confirmButtonColor: "#169840",
          focusConfirm: false,
        });
      }
    },
    close() {
      this.$emit("close", false);
    }
  }
}
</script>

<style>
.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1060;
}

.modal .modal__box {
  width: 90%;
  max-width: 300px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  z-index: 1060;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
}

.modal .modal__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1059;
  backdrop-filter: blur(4px);
}

.modal .modal__footer {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.card__field label {
  font-size: 13px;
  color: #757575;
  text-transform: uppercase;
}

.card__field input {
  height: 40px;
  border: 1px solid #eee;
  padding: 10px;
}

.card__field span {
  font-size: 11px;
  color: #757575;
  font-style: italic;
}

.card__field {
  display: flex;
  flex-direction: column;
}

.modal button {
  padding: 8px 20px;
  text-transform: uppercase;
  background-color: #3c8a42;
  color: #fff;
  font-weight: bold;
  transition: background-color .2s;
  flex: 1 auto;
}

.modal button:first-child {
  border: 1px solid #3c8a42;
  background-color: #fff;
  color: #3c8a42;
}

.modal button:first-child:hover {
  background-color: #f1f1f1;
}

.modal button:hover:not(:first-child) {
  background-color: #2c6030;
}

.code_wrapper {
  text-align: center;
  margin-top: 20px;
}

.code_wrapper hr {
  margin-bottom: 20px;
}

.code_wrapper img {
  display: block;
  margin: 0 auto;
}

.code_wrapper p {
  font-size: 12px;
  color: #757575;
}
</style>