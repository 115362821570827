<template>
  <div class="resetButtonsContainer">
    <button
      @click="() => refresh(item)"
      title="Restaurar"
      class="resetButtons reset"
      :class="{
        disabled:
          this.$store.state.data.items[item].quantidade ===
          this.$store.state.data.items[item].quantidadeOriginal
            ? true
            : false,
      }"
      :style="{
        background:
          'url(' + require('@/assets/icons/refresh.svg') + ')no-repeat center',
      }"
    ></button>
    <button
      title="Aprovar proposta"
      @click.prevent="() => eraser(item)"
      class="resetButtons eraser"
    >
    <md-icon style="color:#169840">thumb_up_off_alt</md-icon>
    </button>

    <button
      v-if="!disabledInfo"
      @click.prevent="() => getUrl(item)"
      title="Negar proposta"
      class="resetButtons moreInformation"
    >
      <md-icon style="color:red">thumb_down_off_alt</md-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: "TableButtonsResetAndRefresh",
  props: {
    item: {},
    disabledInfo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemSelected: null,
      itemSelectedKeys: null,
    }
  },
  methods: {
    getUrl(item) {

      if (this.$route.name === "HomeB2b") {
        this.$router.push({ name: "MoreB2b", params: { item: item } })
      } else {
        this.$router.push({ name: "MoreAfv", params: { item: item } })
      }

    },
    open() {
      this.$modal.show(`modal-more-${this.item}`)
    },
    getDetails() {

      return JSON.stringify(this.itemSelected, null, "\t")
    },

    refresh(item) {
      if (this.$store.state.data.items[item].segregacoes.length) {
        const quantidadeOriginal = this.$store.state.data.items[item].quantidadeOriginal

        this.$store.state.data.items[item].quantidade = quantidadeOriginal
        this.$store.state.data.items[item].preco = this.$store.state.data.items[item].precoOriginal

        this.$store.commit("setQuantityItem")

      }
      else {
        this.$store.state.data.items[item].quantidade = this.$store.state.data.items[item].quantidadeOriginal
        this.$store.state.data.items[item].preco = this.$store.state.data.items[item].precoOriginal
      }
      this.$store.commit("setQuantityItem")
      this.$store.commit('updateItem',
        this.$store.state.data.items[item]
      )
    },
    eraser(item) {
      if (this.$store.state.data.items[item].segregacoes.length) {
        this.$store.state.data.items[item].segregacoes = this.$store.state.data.items[item].segregacoes.map(item => ({ ...item, quantidade: 0, volatil: 0, actionSegregation: false }))
        this.$store.state.data.items[item].quantidade = 0
      }
      else {
        this.$store.state.data.items[item].quantidade = 0
      }
      this.$store.commit("setQuantityItem")
    },
  },
  mounted() {
    if (this.$store.state.data.items[this.item].detalhesProduto !== undefined) {
      this.itemSelected = this.$store.state.data.items[this.item].detalhesProduto
      this.itemSelectedKeys = Object.keys(this.$store.state.data.items[this.item].detalhesProduto)
    }
  }
};
</script>

<style scoped>
.btnMore {
  color: rgb(255, 217, 0);
  font-size: 30px;
  margin: 10px;
}
.container {
}
.header {
  background: #169840;
}
.header,
.btnClose {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.btnClose {
  background: #dfdfdf;
}
.header > span {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.body > table {
  margin: 20px auto;
}
.body > table > tbody {
}
.body > table > tbody > tr {
}
.body > table > tbody > td {
}
.itemKey {
  font-weight: 600;
  text-transform: capitalize;
  margin-right: 20px;
}
.itemDescription {
  padding-left: 15px;
}

.disabled {
  opacity: 0.2;
}
.resetButtonsContainer {
  display: flex;
  min-height: 40px;
  justify-content: center;
}

.resetButtons {
  background-size: 25px !important;
  cursor: pointer;
  width: 40px;
  margin: 0px 0px;
}

.resetButtons:hover {
  opacity: 0.4;
}
.moreInformation {
}
.eraser.disabled {
  cursor: default;
}
.reset.disabled {
  cursor: default;
}
.eraser.disabled:hover,
.reset.disabled:hover {
  opacity: 0.2;
}

@media (max-width: 345px) {
  .resetButtons {
    margin: 0px 0px;
  }
}
</style>
