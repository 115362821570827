<template>
    <button :class="stateClass" @click="click">
        <md-icon class="btn-icon" v-if="action === 'approve'">thumb_up_off_alt</md-icon>
        <md-icon class="btn-icon" v-else>thumb_down_off_alt</md-icon>
        {{ action === "approve" ? "Aprovar" : "Reprovar" }}
    </button>
</template>

<script>
export default {
    name: "ApprovalButton",
    props: {
        action: {
            type: String,
            default: 'approve', // 'approve' or 'reject'
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        stateClass() {
            return {
                active: this.active,
                approve: this.action === 'approve',
                reject: this.action === 'reject',
            }
        }
    },
    methods: {
        click() {
            this.$emit('click');
        }
    }
}
</script>

<style scoped>
button {
    border: 1px solid;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 11px;
    display: flex;
    gap: 4px;
    align-items: center;
    text-transform: uppercase;
}

button.approve:not(.active) {
    color: #169840;
    border-color: #169840;
}

button.reject:not(.active) {
    color: red;
    border-color: red;
}

button.active.approve {
    border-color: #169840;
    color: white;
    background-color: #169840;
}

button.active.reject {
    border-color: red;
    color: white;
    background-color: red;
}

button .btn-icon {
    color: inherit !important;
    font-size: 20px !important;
}
</style>