<template>
    <div class="modal__content">
        <slot />
    </div>
</template>

<script>
export default {
    name: "ModalContent",
}
</script>

<style scoped>
.modal__content {
    padding: 20px;
}
</style>