<template>
  <footer class="footer">
    <div class="footerContent">
      <div class="footerDiv">
        <span>Proposta Web</span>
        <img :src="require('@/assets/images/proposta-logo.png')" />
      </div>
      <div class="footerDiv">
        <span>Feito por</span>
        <img :src="require('@/assets/images/logo-guarani.png')" />
      </div>
    </div>

    <p class="copy">
      ©{{new Date().getFullYear()}}. Todos os direitos reservados |
      <a href="https://www.guaranisistemas.com.br/#!/home" target="_blank"
        >www.guaranisistemas.com.br</a
      >
    </p>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  padding-bottom: 80px;
  width: 100%;
}
.footerContent {
  display: flex;
  justify-content: center;
}
.footerDiv {
  display: flex;
  flex-direction: column;
  margin: 15px 10px;
  margin-bottom: 20px;
}
.footerDiv > span {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
}
.footerDiv > img {
  height: 30px;
}
.copy {
  color: #707070;
  display: flex;
  font-family: "Roboto", sans-serif;
  justify-content: center;
}
.copy > a {
  margin-left: 5px;
}
@media (max-width: 460px) {
  .copy {
    display: block;
    text-align: center;
  }
}
</style>