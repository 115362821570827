<template>
  <div class="quantityCounter">
    <input class="quantity" step="any" ref="price" v-model="preco" v-currency="{
      currency: 'BRL',
      locale: 'pt-br',
      autoDecimalMode: true,
      distractionFree: false,
      precision: casasDecimais,
    }" @input.self="handlePrice" />
  </div>
</template>

<script>
import isNumber from "../../utils/isNumber";
import { mapGetters } from "vuex";
import { setValue, getValue } from "vue-currency-input";

export default {
  name: "TableQuantityCounter",
  props: ["item"],

  data() {
    return {
      preco: 0,
      mounted: false,
    };
  },

  mounted() {
    setValue(this.$refs.price, this.item.preco);
    this.mounted = true;
  },

  computed: {
    ...mapGetters(["total", "casasDecimais"]),
  },
  methods: {
    isNumber,
    handlePrice(event) {
      if (this.mounted) {
        const ipi = this.$store.state.data.items[this.item.positionArray].ipi;
        const preco = getValue(this.$refs.price);
        const index = this.$store.state.data.items.findIndex(
          (item) => item.codigo === this.item.codigo
        );

        this.$store.state.data.items[index].preco = preco;
        this.$store.state.data.items[index].precoComImpostos =
          preco + (preco * ipi) / 100;

        if (
          Number(this.$store.state.data.items[index].quantidade).toFixed(3) !== Number(this.$store.state.data.items[index].quantidadeOriginal).toFixed(3) ||
          this.$store.state.data.items[index].preco.toFixed(2) !== this.$store.state.data.items[index].precoOriginal.toFixed(2)
        ) {
          if (!this.$store.state.data.items[index].hasOwnProperty('justificativa')) {
            this.$store.commit("setItemJustificativa", { add: true, index: index });
          }
        } else if (!this.item.bloqueado) {
          this.$store.commit("setItemJustificativa", { add: false, index: index });
        }
      }
    },
  },
  watch: {
    "item.preco": function () {
      const currPrice = getValue(this.$refs.price);

      if (this.item.preco !== currPrice) {
        setValue(this.$refs.price, this.item.preco);
      }
    },
  },
};
</script>

<style scoped>
.red {
  color: #e80000;
}

.quantityCounter {
  width: 100%;
}

.counterButton,
.quantity {
  text-align: center;
}

.counterButton {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.quantity {
  align-items: center;
  background: #fff;
  border: 1px solid #e6e6e6;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 115px;
}

.quantity:focus {
  outline: none;
}

.less {
  border-right: 1px solid #e4e4e4;
}

.more {
  border-right: 1px solid #e4e4e4;
}

.drop {
  display: block;
}
</style>
