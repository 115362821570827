var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resetButtonsContainer"},[_c('button',{staticClass:"resetButtons reset",class:{
      disabled:
        this.$store.state.data.items[_vm.item].quantidade ===
        this.$store.state.data.items[_vm.item].quantidadeOriginal
          ? true
          : false,
    },style:({
      background:
        'url(' + require('@/assets/icons/refresh.svg') + ')no-repeat center',
    }),attrs:{"title":"Restaurar"},on:{"click":function () { return _vm.refresh(_vm.item); }}}),_c('button',{staticClass:"resetButtons eraser",attrs:{"title":"Aprovar proposta"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.eraser(_vm.item); })($event)}}},[_c('md-icon',{staticStyle:{"color":"#169840"}},[_vm._v("thumb_up_off_alt")])],1),(!_vm.disabledInfo)?_c('button',{staticClass:"resetButtons moreInformation",attrs:{"title":"Negar proposta"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.getUrl(_vm.item); })($event)}}},[_c('md-icon',{staticStyle:{"color":"red"}},[_vm._v("thumb_down_off_alt")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }