<template>
  <div>
    <div v-if="askPassword" class="askPassword">
      <img :src="require('../assets/images/proposta-logo.png')" alt="Proposta Web" class="propostaLogo" />

      <div class="card">
        <h2>Desbloqueie o acesso</h2>

        <form @submit.prevent="handlePassword">
          <div>
            <p style="font-size:12px;">Representante</p>
            <hr style="background-color:#b7b7b7;height:2px;border:0;margin:10px 0;margin-top:5px;" />
            <div class="info">
              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">CÓDIGO</span>
                <span>{{ $store.state.data.codigoRepresentante || "--" }}</span>
              </div>

              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">PREPOSTO</span>
                <span>{{ $store.state.data.preposto || "--" }}</span>
              </div>

              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">NOME</span>
                <span>{{ $store.state.data.representanteNome || "--" }}</span>
              </div>

              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">FONE</span>
                <span>{{ $store.state.data.representanteFone || "--" }}</span>
              </div>

              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">E-MAIL</span>
                <span>{{ $store.state.data.representanteEmail || "--" }}</span>
              </div>
            </div>
          </div>

          <div style="margin-top:20px;">
            <p style="font-size:12px;">Cliente</p>
            <hr style="background-color:#b7b7b7;height:2px;border:0;margin:10px 0;margin-top:5px;" />
            <div class="info">
              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">CÓDIGO</span>
                <span>{{ $store.state.data.clienteCodigo || "--" }}</span>
              </div>

              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">Nº DO DOCUMENTO</span>
                <span>{{ $store.state.data.numeroPedido || "--" }}</span>
              </div>

              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">RAZÃO SOCIAL</span>
                <span>{{ $store.state.data.clienteRazaoSocial || "--" }}</span>
              </div>

              <div style="display:flex;flex-direction:column;">
                <span style="color:#828282;margin-bottom:10px;">NOME FANTASIA</span>
                <span>{{ $store.state.data.clienteNomeFantasia || "--" }}</span>
              </div>
            </div>
          </div>

          <div style="margin-top:20px;">
            <hr style="background-color:#b7b7b7;height:2px;border:0;margin:10px 0;margin-top:5px;" />
            <div class="info">

              <div class="card__field">
                <label>Nº do Pedido</label>
                <div class="card_copy" v-clipboard:copy="$store.state.data['numeroPedido']" v-clipboard:success="onCopy"
                  type="button">
                  {{ $store.state.data['numeroPedido'] }}
                  <md-icon style="color:#707070;margin:0;">content_copy</md-icon>
                </div>
              </div>

              <div class="card__field">
                <label>VR. TOTAL DO PEDIDO S/ IMPOSTOS</label>
                <div class="card_copy" style="justify-content:flex-end;">
                  {{
                    new Intl.NumberFormat("pt-br", {
                      style: "currency",
                      currency: "BRL",
                      currencyDisplay: "symbol",
                    }).format(totalFooter)
                  }}
                </div>
              </div>
            </div>
          </div>

          <div style="margin-top:20px;">
            <div class="info">
              <div class="card__field" style="flex: 1">
                <label>Nome do aprovador(a)<sup>*</sup></label>
                <input type="text" :value="$store.state.data['nomeSupervisor']"
                  @input="event => $store.state.data['nomeSupervisor'] = event.target.value" />
              </div>

              <div class="card__field" style="flex: 1">
                <label>Senha</label>
                <input type="password" :value="password" @input="event => password = event.target.value" />
              </div>
            </div>
          </div>

          <div style="margin-top:20px;">
            <div class="info">
              <div class="card__field" style="flex: 0.3">
                <label>CPF do Aprovador</label>
                <div class="card_copy" style="justify-content:flex-end;">
                  {{
                    maskedCPF
                  }}
                </div>
              </div>
            </div>
          </div>

          <hr style="background-color:#b7b7b7;height:2px;border:0;margin:20px 0;" />

          <p class="card__text">Ou acesse com o código do autenticador cadastrado</p>

          <div class="card__field authenticator">
            <label>Código</label>
            <input type="text" :value="code" @input="event => code = event.target.value" />
          </div>

          <div class="buttonContainer">
            <button type="button" @click="() => isModalOpen = true">Cadastrar autenticador</button>
            <button type="submit">Acessar</button>
          </div>
        </form>
      </div>
    </div>

    <div v-if="showScreenByRouter && !askPassword">
      <div class="home" v-if="showPage">
        <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration"
          :md-active.sync="showSnackbar" md-persistent>
          <span>Connection timeout. Showing limited messages!</span>
          <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
        </md-snackbar>

        <Header />
        <HeaderMobile />
        <CardsEnterprise />
        <div :class="{ container: true, minimal: !padding, maximo: padding }">

          <!-- Ações em lote -->
          <div style="display:flex;gap:10px;align-items:center;padding:20px;border:1px solid #999;border-bottom:0;">
            <Checkbox :is-checked="getIsAllSelectedItems" :is-indetermined="getIsIndeterminate"
              @on-checked-change="handleCheckedChange" style="margin-right: 10px;" />
            <ApprovalButton @click="() => handleJustify('approve')" />
            <ApprovalButton @click="() => handleJustify('reject')" action="reject" />
          </div>

          <CardProducts />

          <div v-show="itemsPaginateLength !== 0">
            <v-page :total-row="itemsLengths" v-model="current"
              @page-change="(info) => pagePhotoChange(info, $store.state.shouldFilter)" align="right" :border="false"
              :first="true" :last="true" :page-number="false"></v-page>
          </div>
        </div>
        <Observation charLimited="200" :data="this.$store.state.data" />
        <TradeRepresentative />

        <Footer />

        <BottomBar />
        <BottomBarMobile />
        <FilterComponent @close="closeFilter" />
      </div>
    </div>

    <ModalAutenticador v-if="isModalOpen" @close="(value) => isModalOpen = value" />
    <ModalJustify v-if="isJustifyModalOpen" @close="isJustifyModalOpen = false" :action="justifyAction" />
  </div>
</template>

<script>
import BottomBar from "../components/BottomBar/BottomBar";
import BottomBarMobile from "../components/BottomBar/BottomBarMobile";
import Header from "../components/Header/Header";
import HeaderMobile from '../components/Header/HeaderMobile'
import Observation from "../components/Observation";
import TableProducts from "../components/TableAndCardProducts/TableProducts";
import TradeRepresentative from "../components/TradeRepresentative.vue";
import Footer from "../components/Footer/Footer";
import stringEmptyToHypen from "../modules/stringEmptyToHyphen";
import CardProducts from '../components/TableAndCardProducts/CardProducts';
import CardsEnterprise from '../components/CardsEnterprise.vue';
import ModalAutenticador from "../components/Modals/ModalAutenticador.vue";
import FilterComponent from "../components/Filter/Filter.vue";
import Checkbox from "../components/Checkbox/Checkbox";
import ApprovalButton from "../components/ApprovalButton/ApprovalButton";
import ModalJustify from "../components/Modals/ModalJustify";
import { mapGetters } from "vuex";
import { formatAndMaskCPF } from "../utils/formatAndMaskCPF";


import axios from "axios";

const BASE_PROD = "https://orcamento.guaraniafv.com.br:7000";

export default {
  name: "Home",
  components: {
    Header,
    HeaderMobile,
    BottomBar,
    BottomBarMobile,
    TableProducts,
    Observation,
    TradeRepresentative,
    Footer,
    CardProducts,
    CardsEnterprise,
    ModalAutenticador,
    FilterComponent,
    Checkbox,
    ApprovalButton,
    ModalJustify,
  },
  data() {
    return {
      arr: [],
      itemsLength: [],
      current: 1,
      pageArr: [],
      textarea: "",
      flag: false,
      pageSize: 10,
      stringEmptyToHypen,
      showSnackbar: this.$store.state.toastSave,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      let: 0,
      askPassword: true,
      password: "",
      code: "",
      isModalOpen: false,
      isAllSelected: true,
      isSelectedIndeterminate: false,
      isJustifyModalOpen: false,
      justifyAction: 'approve',
    };
  },

  methods: {
    handleJustify(action) {
      if (this.$store.state.selectedItems.length > 0) {
        this.isJustifyModalOpen = true;
        this.justifyAction = action;
      } else {
        this.$swal({
          icon: 'error',
          title: 'Atenção',
          text: "Selecione ao menos um item para justificar",
          confirmButtonColor: "#169840",
          focusConfirm: false,
        });
      }
    },
    handleCheckedChange(checked) {
      this.$store.commit("setSelectedItems", checked);
    },
    closeFilter() {
      this.$store.commit("setShowFilter", false);
    },
    onCopy() {
      this.$swal({
        icon: 'success',
        title: '',
        text: "Número do pedido copiado",
        confirmButtonColor: "#169840",
        focusConfirm: false,
      });
    },
    async handlePassword() {
      if (!this.$store.state.data['nomeSupervisor']) {
        this.$swal({
          icon: 'error',
          title: 'Atenção',
          text: "É necessário fornecer um nome",
          confirmButtonColor: "#169840",
          focusConfirm: false,
        })

        return;
      }

      if (this.password) {
        const encoder = new TextEncoder();
        const bArray = encoder.encode(this.password).join('');

        if (this.$store.state.data.nomeSupervisor === process.env.VUE_APP_ADMIN_USER && this.password === process.env.VUE_APP_ADMIN_PASSWORD) {
          document.querySelector(".askPassword").style.opacity = 0;

          setTimeout(() => {
            this.askPassword = false;
          }, 300);

          return;
        }

        if (this.$store.state.data.token === bArray) {
          document.querySelector(".askPassword").style.opacity = 0;

          setTimeout(() => {
            this.askPassword = false;
          }, 300);
        } else {
          this.$swal({
            icon: 'error',
            title: 'Atenção',
            text: "Senha inválida",
            confirmButtonColor: "#169840",
            focusConfirm: false,
          })
        }
      } else if (this.code) {
        try {
          await axios.post(`${BASE_PROD}/2fa/verify`, { token: this.code, cpf: this.$store.state.data.cpfSupervisor })

          document.querySelector(".askPassword").style.opacity = 0;

          setTimeout(() => {
            this.askPassword = false;
          }, 300);
        } catch (err) {
          this.$swal({
            icon: 'error',
            title: 'Atenção',
            text: err.response?.data.message || "Não foi possível validar o código",
            confirmButtonColor: "#169840",
            focusConfirm: false,
          })
        }

      }

    },
    pagePhotoChange(pInfo, shouldFilter = false) {
      this.pageSize = pInfo.pageSize;

      if (this.items !== null) {
        let items = [];

        if (shouldFilter) {
          items = this.items.filter(item => item.bloqueado === true);
        } else {
          items = this.items;
        }

        this.pageArr.splice(0, this.pageArr.length);
        let start = 0,
          end = 0;

        start = pInfo.pageSize * (pInfo.pageNumber - 1);
        end = start + pInfo.pageSize;

        if (end > items.length) end = items.length;

        for (let i = start; i < end; i++) {
          this.pageArr.push(items[i]);
        }

        this.$store.commit("setItemsPaginate", this.pageArr)

        this.$store.commit("updateVPage", pInfo.pageNumber)
      } else {
        return;
      }
    },
    showImagemEmpresa(item) {
      return item.imagemEmpresa === "" ? false : true;
    },
  },
  computed: {
    ...mapGetters(["getIsAllSelectedItems", "getIsIndeterminate", "totalFooter"]),
    maskedCPF() {
      return formatAndMaskCPF(this.$store.state.data.cpfSupervisor);
    },
    padding() {
      return this.$store.state.headerShowMinimal
    },
    showScreenByRouter() {
      if (this.$route.name === "MoreB2b" || this.$route.name === "MoreAfv") {
        return false
      } else {
        return true
      }
    },

    datas() {
      return this.$store.state.data;
    },
    items() {
      return this.$store.state.items;
    },
    filters() {
      return this.$store.state.filtros;
    },
    itemsPaginate() {
      return this.$store.state.itemsPaginate
    },
    itemsPaginateLength() {
      return this.$store.state.itemsPaginate.length
    },
    itemsLengths() {
      let items = [];

      if (this.$store.state.shouldFilter) {
        items = this.$store.state.items.filter(item => item.bloqueado === true);
      } else {
        items = this.$store.state.items;
      }
      return items.length
    },
    showPage() {
      return this.$store.state.showPage
    }
  },
  mounted() {
    if (this.datas === undefined) {
      this.$store.dispatch("getData", {
        cpfCnpj: this.$route.params.cpfCnpj,
        numPedido: this.$route.params.numPedido,
      });
    }
    this.$store.state.flagControl = false
    localStorage.setItem('setCurrentRoute', null);
  },
  watch: {
    items() {
      //if (this.flag === false) {
      this.itemsLength = this.$store.state.data.items;
      this.pagePhotoChange({ pageNumber: 1, pageSize: this.pageSize }, this.$store.state.shouldFilter);
      //this.flag = true
      //}
    },
    itemsPaginate(news, old) {

      if (news.length > this.pageSize) {

        this.pagePhotoChange({
          pageNumber: 1,
          pageSize: this.pageSize
        }, this.$store.state.shouldFilter)
      }
    },
    '$store.state.shouldFilter': function (shouldFilter) {
      this.current = 1;
      this.pagePhotoChange({ pageNumber: 1, pageSize: this.pageSize }, shouldFilter);

    }
  },
};
</script>


<style>
.et-wrapper.et-top {
  top: 10px !important;
  bottom: auto;
}

.et-wrapper.green {
  background-color: #169840;
}

.et-wrapper .et-close {
  position: absolute;
  top: 0;
  right: 5px;
  color: #fff !important;
}

@media (max-width: 768px) {
  .et-wrapper.et-top {
    top: 70px !important;
    bottom: auto;
  }
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
  border-color: #d3d8e000 !important;
}

.md-focused {
  caret-color: #cbcbcb !important;
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
  border-color: #cbcbcb;
}

.md-button-content {
  display: flex !important;
  align-items: center;
}

.md-button {
  margin-left: 10px !important;
}

.md-button:not([disabled]).md-focused:before,
.md-button:not([disabled]):active:before,
.md-button:not([disabled]):hover:before {
  background-color: currentColor;
  opacity: 0;
}

.td-container {
  padding: 0px !important;
  margin-left: 10px !important;
}

.th-container,
.th-label {
  overflow: visible !important;
  text-align: inherit;
}

.th-container {
  height: auto !important;
}
</style>
<style scoped>
.minimal {
  padding-top: 250px;
}

.maximo {
  padding-top: 150px;
}

.container {
  max-width: 1130px;
  margin: 0 auto;
}

.home {
  position: relative;
  background: #fff;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    padding-top: 0px;
  }
}

.propostaLogo {
  max-width: 300px;
  margin-top: 10px;
}

.askPassword {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f7f7f7;
  z-index: 1055;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
}

.askPassword .card {
  margin-top: 30px;
  background-color: #fefefe;
  padding: 30px;
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .askPassword .card {
    padding: 30px 15px;
    margin-bottom: 90px;
  }
}

.askPassword .card .info {
  display: flex;
  gap: 30px;
  font-size: 12px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .askPassword .card .info {
    gap: 10px;
  }

  .askPassword .card .info .card__field {
    flex: 1;
    min-width: 200px;
  }
}

.askPassword .card h2 {
  font-size: 1.3rem;
  line-height: 100%;
  margin-bottom: 20px;
  text-transform: uppercase;
}

@media (max-width: 400px) {
  .askPassword .card h2 {
    font-size: 1.25rem;
  }
}

.askPassword .buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.askPassword .card button {
  padding: 8px 20px;
  text-transform: uppercase;
  background-color: #169840;
  color: #fff;
  transition: background-color .2s;
  border-radius: 4px;
}

.askPassword .card button:first-child {
  border: 1px solid #169840;
  background-color: #fff;
  color: #169840;
}

.askPassword .card button:first-child:hover {
  background-color: #f1f1f1;
}

.askPassword .card button:hover:not(:first-child) {
  background-color: #169840;
}

.askPassword .card__field label {
  font-size: 13px;
  color: #757575;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.askPassword .card__field input {
  height: 40px;
  border: 1px solid #707070;
  padding: 10px;
}

.askPassword .card__field {
  display: flex;
  flex-direction: column;
}

.askPassword .card__field:first-child {
  cursor: pointer;
}

.card_copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 10px;
  background-color: #e4e4e4;
  height: 44px;
}

.divider_wrapper {
  position: relative;
}

.divider {
  display: block;
  margin: 20px 0;
}

.divider_wrapper span {
  position: absolute;
  top: -8px;
  transform: translateX(-50%);
  left: 50%;
  background: #fff;
  color: #757575;
  padding: 0 5px;
}

.card__text {
  color: #757575;
  margin-bottom: 20px;
  font-size: 13px;
}

.card__field.authenticator {}

.card__field.authenticator input {
  font-size: 22px;
  text-align: center;
  max-width: 150px;
}
</style>
