<template>
    <div class="modal-component">
        <div class="modal-component__overlay" @click="click" />
        <div class="modal-component__body">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalComponent",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        click() {
            this.$emit("click");
        },
    },
}
</script>

<style scoped>
.modal-component {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.modal-component .modal-component__overlay {
    background: rgba(0, 0, 0, 0.534);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(4px);
}

.modal-component .modal-component__body {
    width: 100%;
    max-width: 310px;
    background-color: white;
    z-index: 100;
}
</style>